import React from 'react'
import { NavLink } from 'react-router-dom'

import './unauthorizedBonusProgram.css'

// import WheelOfFortune from './Group 207.svg'
import WheelOfFortune from './Group 207.webp'
import ButtonLevel from '../../buttonLevel/ButtonLevel'

const UnauthorizedBonusProgram = () => {
	return (
		<section className='bonusprogram ' id='bonusprogram'>
			<div className='bonusprogram__container'>
				<h1 className='bonusprogram__title'>Бонусная программа</h1>
				<div className='bonusprogram__content'>
					<NavLink
						to='/level-and-bonuses'
						className='bonusprogram__content-img'
					>
						<img src={WheelOfFortune} alt='/' className='bonusprogram__img' />
					</NavLink>
					<div className='bonusprogram__sections'>
						<div className='bonusprogram__money'>
							<div className='bonusprogram__money-title'>
								<h3 className='bonusprogram__title'>Денежные вознаграждение</h3>
							</div>
							<ul className='bonusprogram__list'>
								<li className='bonusprogram__list-item'>
									Получайте <span>до 20%</span> за целевую рекомендацию
								</li>
								<li className='bonusprogram__list-item'>
									Оставляйте больше рекомендаций и{' '}
									<span>повышайте свой уровень</span>
								</li>
								<li className='bonusprogram__list-item'>
									При повышении уровня{' '}
									<span>сумма вознаграждений увеличивается</span>
								</li>
							</ul>
						</div>
						<div className='bonusprogram__wheel'>
							<ul className='bonusprogram__list wheel'>
								<li className='bonusprogram__list-item'>
									Получайте <span>ценные подарки</span> за рекомендации
								</li>
								<li className='bonusprogram__list-item'>
									Открывайте уровень — Амбассадор MezEx и участвуйте в{' '}
									<span>Суперколесе</span>
								</li>
								<li className='bonusprogram__list-item'>
									Участвуйте в ежемесячном розыгрыше{' '}
									<span>iPhone 15 PRO MAX</span>
								</li>
							</ul>
							<div className='bonusprogram__wheel-title'>
								<h3 className='bonusprogram__title'>Колесо фортуны</h3>
							</div>
						</div>
					</div>
				</div>
				<ButtonLevel />
			</div>
		</section>
	)
}

export default UnauthorizedBonusProgram
