import './helpinfo.css'

import LogoWhatsApp from './Logo-whatsapp.png'
import RaisaPhoto from './Nurieva_Raisa.webp'

const HelpInfo = () => {
	return (
		<section className='help-info'>
			<div className='help-info__container'>
				<h1 className='help-info__title'>Помощь</h1>
				<div className='help-info__content'>
					<div className='help-info__data'>
						<h1 className='help-info__title help-info__name'>Нуриева Раиса</h1>
						<h3 className='help-info__title help-info__post'>
							Специалист по работе с партнерами
						</h3>
						<h3 className='help-info__title help-info__reasoning'>
							Если у вас остались вопросы по сайту и работе реферальной
							программе свяжитесь с нами!
						</h3>
						<div className='help-info__connection'>
							<h3 className='help-info__title help-info__number-data'>
								Телефон для связи:
							</h3>
							<div className='help-info__connection-data'>
								<img
									src={LogoWhatsApp}
									alt=''
									className='help-info__connection-img'
								/>
								<a
									href='tel:79097291592'
									className='help-info__connection-link'
								>
									+79097291592
								</a>
							</div>
						</div>
					</div>
					<img src={RaisaPhoto} alt='' className='help-info__content-img' />
				</div>
			</div>
		</section>
	)
}

export default HelpInfo
