import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ButtonRequestReport from '../buttonrequestreport/buttonRequestReport'
import './myapplications.css'

const MyApplications = () => {
	const [contactList, setContactList] = useState([])
	const [filterMenuVisible, setFilterMenuVisible] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [userPhone, setUserPhone] = useState('')
	const [status, setStatus] = useState('')
	const [name, setName] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [tempName, setTempName] = useState('')
	const [tempPhoneNumber, setTempPhoneNumber] = useState('')

	const handleNameChange = event => {
		setTempName(event.target.value)
	}

	const handlePhoneNumberChange = event => {
		const cleanedValue = event.target.value.replace(/[^\d\s-()+]/g, '')
		setTempPhoneNumber(cleanedValue)
	}

	const handleSearchClick = async () => {
		setCurrentPage(1)
		setName(tempName)
		setPhoneNumber(tempPhoneNumber)

		await fetchContacts(1, status, tempName, tempPhoneNumber)
	}

	const fetchContacts = async (page, status, name, phoneNumber) => {
		if (page > 100) return

		try {
			const phone = localStorage.getItem('userPhone')

			const params = new URLSearchParams({
				status: status !== '' ? status : undefined,
				name: name || undefined,
				contact_phone: phoneNumber || undefined,
				page: page,
			})

			const response = await axios.post(
				`${process.env.REACT_APP_HOST}/data/get_contacts?${params.toString()}`,
				{ phone },
				{
					auth: {
						username: process.env.REACT_APP_LOGIN,
						password: process.env.REACT_APP_PASSWORD,
					},
				}
			)

			const data = response.data
			if (!data || data.length === 0) {
				setContactList([])
				return
			}

			const formattedContacts = data.map(contact => ({
				name: contact.contact_name,
				phone: contact.contact_phone,
				status: contact.contact_status,
				date: new Date(contact.created_at).toLocaleDateString(),
			}))

			setContactList(formattedContacts)
		} catch (error) {
			console.error('Ошибка получения заявок:', error)
		}
	}

	useEffect(() => {
		const userData = JSON.parse(localStorage.getItem('userData'))
		if (userData && userData.contacts) {
			setUserPhone(userData.phone)
			if (currentPage === 1) {
				const contacts = userData.contacts
				const formattedContacts = contacts.map(contact => ({
					date: new Date(contact.created_at).toLocaleDateString(),
					name: contact.contact_name,
					phone: contact.contact_phone,
					status: contact.contact_status,
				}))
				setContactList(formattedContacts)
			} else {
				fetchContacts(currentPage, status, name, phoneNumber)
			}
		} else {
			fetchContacts(currentPage, status, name, phoneNumber)
		}
	}, [currentPage, status, name, phoneNumber])

	const formatStatus = status => {
		switch (status) {
			case 0:
				return <span className='filter-at-work'>В работе</span>
			case 1:
			case 2:
			case 3:
				return <span className='filter-fulfilled'>Исполнен</span>
			case 4:
				return <span className='filter-inactive'>Неактивен</span>
			default:
				return
		}
	}

	const handlePageChange = page => {
		if (page > 0 && page < 101) {
			setCurrentPage(page)
			fetchContacts(page, status, name, phoneNumber)
		}
	}

	const renderPagination = () => {
		const maxPagesToShow = 3
		const pages = []

		pages.push(
			<button
				className={`pagination__button pagination__button-left ${
					currentPage === 1 ? 'disabled' : ''
				}`}
				onClick={() => handlePageChange(currentPage - 1)}
				disabled={currentPage === 1}
				key='prev'
			></button>
		)

		const startPage = Math.max(1, currentPage - 1)
		const endPage = Math.min(currentPage + 1, 100)

		const numPagesToShow = Math.min(maxPagesToShow, endPage - startPage + 1)
		for (let i = 0; i < numPagesToShow; i++) {
			const pageNumber = startPage + i
			pages.push(
				<button
					key={pageNumber}
					className={`pagination__button ${
						currentPage === pageNumber ? 'pagination__button-active' : ''
					}`}
					onClick={() => handlePageChange(pageNumber)}
				>
					{pageNumber}
				</button>
			)
		}

		pages.push(
			<button
				className={`pagination__button pagination__button-right ${
					currentPage === 100 ? 'disabled' : ''
				}`}
				disabled={currentPage === 100}
				onClick={() => handlePageChange(currentPage + 1)}
				key='next'
			></button>
		)

		return <div className='pagination'>{pages}</div>
	}

	const handleStatusClick = async filterText => {
		let filterValue
		switch (filterText) {
			case 'В работе':
				filterValue = status === 0 ? '' : 0
				break
			case 'Исполнен':
				filterValue = status === 1 ? '' : 1
				break
			case 'Неактивен':
				filterValue = status === 2 ? '' : 2
				break
			default:
				filterValue = ''
		}

		setStatus(filterValue)
		setCurrentPage(1)

		await fetchContacts(1, filterValue, name, phoneNumber)
	}

	return (
		<section className='my-applications'>
			<div className='my-applications__container'>
				<h1 className='my-applications__title'>Мои заявки</h1>
				<div className='my-applications__content'>
					<div className='my-application__function'>
						<div className='my-applications__status' tabIndex='0'>
							<div className='my-application__search'>
								<div className='my-application__input'>
									<input
										type='text'
										id='inputSearchName'
										className='my-application__name'
										placeholder='ФИО...'
										value={tempName}
										onChange={handleNameChange}
									/>
									<input
										type='tel'
										id='inputSearchTelephone'
										className='my-application__telephone'
										placeholder='Телефон...'
										value={tempPhoneNumber}
										onChange={handlePhoneNumberChange}
									/>
									<button
										className='my-application__button-search'
										onClick={handleSearchClick}
									>
										Поиск
									</button>
								</div>
							</div>
							<div id='filterMenu' className='my-application__filter-menu'>
								<div
									className={`filter-option filter-at-work ${
										status === 0 ? 'selected' : ''
									}`}
									onClick={() => handleStatusClick('В работе')}
								>
									В работе
								</div>
								<div
									className={`filter-option filter-fulfilled ${
										status === 1 ? 'selected' : ''
									}`}
									onClick={() => handleStatusClick('Исполнен')}
								>
									Исполнен
								</div>
								<div
									className={`filter-option filter-inactive ${
										status === 2 ? 'selected' : ''
									}`}
									onClick={() => handleStatusClick('Неактивен')}
								>
									Неактивен
								</div>
							</div>
						</div>
					</div>

					<div className='my-applications__wrapper'>
						<ul className='my-applications__list application-wrapper'>
							<li className='my-applications__list-item my-applications__titles my-applications__titles-date line-right'>
								Дата добавления
							</li>
							<li className='my-applications__list-item my-applications__titles line-right'>
								ФИО
							</li>
							<li className='my-applications__list-item my-applications__titles line-right'>
								Телефон
							</li>
							<li className='my-applications__list-item my-applications__titles'>
								Статус
							</li>
						</ul>
						<ul className='my-applications__list application-wrapper'>
							{contactList.length > 0 &&
								contactList.map((contact, index) => (
									<React.Fragment key={index}>
										<li className='my-applications__list-item my-applications__titles-date line-top line-right'>
											{contact.date}
										</li>
										<li className='my-applications__list-item line-top line-right'>
											{contact.name}
										</li>
										<li className='my-applications__list-item line-top line-right'>
											{contact.phone}
										</li>
										<li className='my-applications__list-item line-top'>
											{formatStatus(contact.status)}
										</li>
									</React.Fragment>
								))}
						</ul>
					</div>
				</div>
				{renderPagination()}
				<ButtonRequestReport />
			</div>
		</section>
	)
}

export default MyApplications
