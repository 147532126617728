import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './leaderboadr.css'

function LeaderBoard() {
	const [leaderData, setLeaderData] = useState([]) // Состояние для хранения данных о лидерах
	const [userPlaceData, setUserPlaceData] = useState(null)
	const [userRecTop, setUserRecTop] = useState(0)

	useEffect(() => {
		const fetchLeaderData = async () => {
			try {
				const response = await axios.get(
					process.env.REACT_APP_HOST + '/data/top',
					{
						auth: {
							username: process.env.REACT_APP_LOGIN,
							password: process.env.REACT_APP_PASSWORD,
						},
					}
				)
				setLeaderData(response.data)
			} catch (error) {
				console.error('Error fetching leader data:', error)
			}
		}

		fetchLeaderData()
	}, [])

	// Получение данных о текущем пользователе из хранилища
	useEffect(() => {
		const storedUserData = localStorage.getItem('userData')
		if (storedUserData) {
			const userData = JSON.parse(storedUserData)
			setUserPlaceData(userData)
			const userRecTop = userData.count_rec_top
			setUserRecTop(userRecTop)
		}
	}, [])
	// Функция для склонения слова "номер"
	const declineNumber = number => {
		const cases = [2, 0, 1, 1, 1, 2]
		let word = 'клиент'
		if (number % 100 > 4 && number % 100 < 20) {
			word += 'ов'
		} else {
			word += ['ов', '', 'а', 'а', 'а', 'ов'][Math.min(number % 10, 5)]
		}
		return word
	}

	return (
		<section className='leader-board' id='leader-board'>
			<div className='leader-board__container'>
				<h1 className='leader-board__title'>Рейтинг рекомендателей</h1>
				<div className='leader-board__content'>
					<div className='leader-board__leaders'>
						<ul className='leader-board__list-top3'>
							{leaderData.slice(0, 3).map((leader, index) => (
								<li className='leader-board__list-item-top' key={index}>
									<div className='leader-board__place'>
										<h1 className='leader-board__title'>{index + 1}</h1>
									</div>
									<p className='leader-board__nickname leader-board__nickname-top3'>
										{leader.nickname}
									</p>
									<p className='leader-board__desc'>
										{leader.count_rec} {declineNumber(leader.count_rec)}
									</p>
								</li>
							))}
						</ul>
					</div>
					<div className='leader-board__top5'>
						<ul className='leader-board__list-top5'>
							{leaderData.slice(3, 5).map((leader, index) => (
								<li className='leader-board__list-item-top5' key={index}>
									<div className='leader-board__place'>
										<h3 className='leader-board__title top5'>{index + 4}</h3>
									</div>
									<p className='leader-board__nickname'>{leader.nickname}</p>
									<p className='leader-board__desc'>
										{leader.count_rec} {declineNumber(leader.count_rec)}
									</p>
								</li>
							))}
						</ul>
					</div>
					<div className='empty'>...</div>
					{userPlaceData && (
						<div
							className={
								userRecTop === 0
									? 'leader-board__your-place-link'
									: 'leader-board__your-place'
							}
						>
							{userRecTop === 0 ? (
								<a
									href='#application'
									className='leader-board__desc leader-board__link'
								>
									Оставляйте свои рекомендации и попадайте в рейтинг!
								</a>
							) : (
								<>
									<h3 className='leader-board__title leader-board__title-user'>
										{userPlaceData.place}
									</h3>
									<p className='leader-board__nickname'>
										{userPlaceData.nickname}
									</p>
									<p className='leader-board__desc'>
										{userRecTop} {declineNumber(userRecTop)}
									</p>
								</>
							)}
						</div>
					)}
					<span className='separation'></span>
					<p className='leader-board__desc leader-board__info'>
						При формировании рейтинга учитывается количество добавленных целевых
						рекомендации за 1 месяц. При попадании в топ-10 и в топ-3 вы
						получаете <b>билет на прокрутку колеса</b>
					</p>
				</div>
			</div>
		</section>
	)
}

export default LeaderBoard
