import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LevelAndBonuses from '../components/authorizedBlocks/levelsandbonuses/LevelAndBonuses'
import PrizeHistory from '../components/prizehistory/PrizeHistory'
import PopularQuestions from '../components/popularQuestions/PopularQuestions'

const Prizes = () => {
	const navigate = useNavigate()

	useEffect(() => {
		// Проверка авторизации при загрузке компонента
		const loggedIn = localStorage.getItem('isLoggedIn')
		if (!loggedIn) {
			// Если пользователь не авторизован, перенаправляем его на главную страницу
			navigate('/')
		}
	}, [navigate])
	return (
		<>
			<LevelAndBonuses />
			<PrizeHistory />
			<PopularQuestions />
		</>
	)
}

export default Prizes
