import ButtonPersonalAccount from '../../buttonPersonalAccount/ButtonPersonalAccount'
import './unauthorizedHero.css'

import HeroBackground from './Фон_главная_с_цветом.svg'

const UnauthorizedHero = () => {
	return (
		<section className='hero' id='unauthorized-hero'>
			<img src={HeroBackground} alt='' className='hero-background-img' />
			<div className='hero__container'>
				<div className='hero__tagline'>
					<h1 className='hero__title hero-titles'>
						<span>Вы рекомендуете — Мы платим</span>
					</h1>
					<div className='hero__text'>
						<h1 className='hero__title'>
							<span>ЗАПЛАТИМ </span> ДО 20% ЗА РЕКОМЕНДАЦИЮ КЛИЕНТА
						</h1>
						<p className='hero__subtitle'>
							Оставляйте номера людей, кому необходимо финансирование, и
							получайте вознаграждение
						</p>
					</div>
				</div>
				<ButtonPersonalAccount />
			</div>
		</section>
	)
}

export default UnauthorizedHero
