import './unauthorizedEarnings.css'

import EarningsBackground from './../../../img/background/Group 102.svg'
import EarningsAvatarSender from './../../../img/icons/Group 151.svg'
import EarningsAvatarRecipient from './../../../img/icons/Ellipse 59.svg'

const UnauthorizedEarnings = () => {
	return (
		<section className='earnings' id='earnings'>
			<div className='earnings__container'>
				<h1 className='earnings__title'>Как заработать с MezEx</h1>
				<h3 className='earnings__title'>
					Зарабатывайте, оставляя рекомендацию (контакт человека), которому
					необходимо финансирование под залог недвижимости или авто! Число
					рекомендаций и сумма вознаграждения не ограничены
				</h3>
			</div>
			<div className='earnings__content'>
				<img src={EarningsBackground} alt='' className='earnings__background' />
				<div className='earnings__container'>
					<div className='earnings__recipient'>
						<ul className='earnings__list'>
							<li className='earnings__list-item white'>
								<h3 className='earnings__title'>Чьи контакты оставлять?</h3>
							</li>
						</ul>
						<img
							src={EarningsAvatarRecipient}
							alt=''
							className='earnings__recipient-avatar'
						/>
					</div>
					<div className='earnings__sender'>
						<img
							src={EarningsAvatarSender}
							alt=''
							className='earnings__sender-avatar'
						/>
						<ul className='earnings__list'>
							<li className='earnings__list-item blue'>
								<h3 className='earnings__title'>
									Клиентов, которым вы отказали в финансировании
								</h3>
							</li>
							<li className='earnings__list-item blue'>
								<h3 className='earnings__title'>
									Клиентов, которым вы не можете предоставить финансирование
								</h3>
							</li>
							<li className='earnings__list-item blue'>
								<h3 className='earnings__title'>
									Ваших друзей/знакомых, кому необходимо финансирование
								</h3>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	)
}

export default UnauthorizedEarnings
