import ButtonMainSite from '../buttonMainSite/ButtonMainSite'
import './aboutUs.css'

const AboutUs = () => {
	return (
		<section className='about-us' id='about-us'>
			<div className='about-us__container'>
				<h1 className='about-us__title'>Что такое MezEx</h1>
				<div className='about-us__content'>
					<div className='about-us__text'>
						<h3 className='about-us__title'>
							MezEx — это smart сервис для получения финансирования и
							инвестиционного дохода
						</h3>
						<p className='about-us__subtitle'>
							Мы объединяем на своей платформе
						</p>
					</div>
					<ul className='about-us__list'>
						<li className='about-us__list-item'>
							<h3 className='about-us__title'>
								Людей, которым необходимо финансирование
							</h3>
						</li>
						<li className='about-us__list-item'>
							<h3 className='about-us__title'>
								Финансовых специалистов и экспертов
							</h3>
						</li>
						<li className='about-us__list-item'>
							<h3 className='about-us__title'>
								Частных инвесторов и кредиторов, финансовые организации
							</h3>
						</li>
					</ul>
				</div>
				<ButtonMainSite />
			</div>
		</section>
	)
}

export default AboutUs
