import React, { useState, useEffect, useRef } from 'react'
import './popularquestions.css'

import QuestionsBackground from '../../img/background/Group 102.svg'
import QuestionsBackgroundLinear from '../../img/background/Background-linear.svg'

import QuestionsLogo from './popular-questions__logo.svg'

import LevelTable from './level-table.svg'
import LevelTableMobile from './LevelTableMobile.svg'
import MoneyTable1 from './MoneyTable1.svg'
import MoneyTable2 from './MoneyTable2.svg'
import MoneyTable1Mobile from './MoneyTable1Mobile.svg'
import MoneyTable2Mobile from './MoneyTable2Mobile.svg'
import ClientsTable1 from './ClientsTable1.svg'
import ClientsTable2 from './ClientsTable2.svg'
import ClientsTable1Mobile from './ClientsTable1Mobile.svg'
import ClientsTable2Mobile from './ClientsTable2Mobile.svg'

import PaperClip from '../../img/icons/Paperclip.svg'
import SmileCircle from '../../img/icons/SmileCircle.svg'
import CameraSquare from '../../img/icons/CameraSquare.svg'

const PopularQuestions = () => {
	const [showQuestions, setShowQuestions] = useState(false)
	const [messages, setMessages] = useState([])
	const [messageIndex, setMessageIndex] = useState(0)
	const [newMessages, setNewMessages] = useState([])
	const [isMobile, setIsMobile] = useState(false)
	const messagesEndRef = useRef(null)

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 1024)
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	const toggleQuestions = () => {
		setShowQuestions(prevState => !prevState)
	}

	const handleLevelsClick = () => {
		setShowQuestions(true)

		const messagesToAdd = [
			<div key='message1' className='questions-level__recipient-item'>
				<h3 className='popular-questions__title messages-title'>
					Расскажи подробнее про уровни
				</h3>
			</div>,
			<div key='message2' className='questions-level__sender-item'>
				<h3 className='popular-questions__title messages-title'>
					Уровень присваивается каждый месяц на основе действий за 3 последних
					месяца
				</h3>
			</div>,
			<img
				key='message3'
				src={isMobile ? LevelTableMobile : LevelTable}
				alt=''
				className={`questions-level__table ${
					isMobile ? 'table-mobile' : 'table-desktop'
				}`}
			/>,
			<div key='message4' className='questions-level__sender-item'>
				<h3 className='popular-questions__title messages-title'>
					<span>Целевая рекомендация</span> — контакт человека, который готов
					рассматривать предложения по финансированию под залог, но в данный
					момент острой потребности в деньгах нет.
				</h3>
			</div>,
			<div key='message5' className='questions-level__sender-item last-message'>
				<h3 className='popular-questions__title messages-title'>
					<span>Целевая актуальная рекомендация</span> — контакт человека,
					которому необходимо финансирование и он готов работать под залог и
					приступить к работе прямо сейчас.
				</h3>
			</div>,
			<div key='message6' className='questions-level__recipient-item'>
				<h3 className='popular-questions__title messages-title'>
					<span>Покажи пример</span>
				</h3>
			</div>,
			<div key='message7' className='questions-level__sender-item'>
				<h3 className='popular-questions__title messages-title'>
					Вы оставили 5 контактов. Ваш уровень сейчас М
				</h3>
			</div>,
			<div key='message8' className='questions-level__sender-item'>
				<h3 className='popular-questions__title messages-title'>
					Двоим из них сейчас не нужны деньги, но они готовы рассмотреть
					финансирование позже — за них вы получаете 80 рублей
				</h3>
			</div>,
			<div
				key='message9'
				className='questions-level__sender-item last-message end-dialogue'
			>
				<h3 className='popular-questions__title messages-title'>
					Троим из них необходимо финансирование прямо сейчас — за них вы
					получаете 360 рублей
				</h3>
			</div>,
		]

		setMessages([])
		setMessageIndex(0)
		setNewMessages(messagesToAdd)
	}

	const handleMoneysClick = () => {
		setShowQuestions(true)

		const messagesToAdd = [
			<div key='message1' className='questions-moneys__recipient-item'>
				<h3 className='popular-questions__title messages-title'>
					Как получить максимальное вознаграждение?
				</h3>
			</div>,
			<div key='message2' className='questions-moneys__sender-item'>
				<h3 className='popular-questions__title messages-title'>
					Если по вашей рекомендации клиент получает финансирование — Вам
					начисляется процентное вознаграждение от суммы комиссии сервиса MezEx
				</h3>
			</div>,
			<div key='message3' className='questions-moneys__sender-item'>
				<h3 className='popular-questions__title messages-title'>
					Процент зависит от вашего уровня:
				</h3>
			</div>,
			<img
				key='message4'
				src={isMobile ? MoneyTable1Mobile : MoneyTable1}
				alt=''
				className={`questions-moneys__table ${
					isMobile ? 'table-mobile' : 'table-desktop'
				}`}
			/>,
			<div key='message5' className='questions-moneys__recipient-item'>
				<h3 className='popular-questions__title messages-title'>
					<span>Покажи пример</span>
				</h3>
			</div>,
			<div key='message6' className='questions-moneys__sender-item'>
				<h3 className='popular-questions__title messages-title'>
					Вы оставили рекомендацию и клиент получил финансирование. Комиссия
					сервиса составила 100 000 рублей
				</h3>
			</div>,
			<div key='message7' className='questions-moneys__sender-item'>
				<h3 className='popular-questions__title messages-title'>
					В зависимости от вашего уровня сумма вознаграждения будет равна
				</h3>
			</div>,
			<img
				key='message9'
				src={isMobile ? MoneyTable2Mobile : MoneyTable2}
				alt=''
				className={`questions-moneys__table end-dialogue ${
					isMobile ? 'table-mobile' : 'table-desktop'
				}`}
			/>,
		]

		setMessages([])
		setMessageIndex(0)
		setNewMessages(messagesToAdd)
	}

	const handleClientsClick = () => {
		setShowQuestions(true)

		const messagesToAdd = [
			<div key='message1' className='questions-clients__recipient-item'>
				<h3 className='popular-questions__title messages-title'>
					А если я оставил рекомендацию, но клиент получил финансирование не
					сразу, а через месяц, два, три и тд?
				</h3>
			</div>,
			<div key='message2' className='questions-clients__sender-item'>
				<h3 className='popular-questions__title messages-title'>
					<span>Вы тоже получите своё вознаграждение</span>
				</h3>
			</div>,
			<div key='message3' className='questions-clients__sender-item'>
				<h3 className='popular-questions__title messages-title'>
					Ваша рекомендация попадает в нашу базу и при возобновлении работы с
					ней вы получаете вознаграждение от <span>суммы комиссии</span> сервиса
					MezEx
				</h3>
			</div>,
			<div key='message4' className='questions-clients__sender-item'>
				<h3 className='popular-questions__title messages-title'>
					В зависимости от вашего уровня сумма вознаграждения будет равна
				</h3>
			</div>,
			<img
				key='message5'
				src={isMobile ? ClientsTable1Mobile : ClientsTable1}
				alt=''
				className={`questions-clients__table ${
					isMobile ? 'table-mobile' : 'table-desktop'
				}`}
			/>,
			<div key='message6' className='questions-clients__recipient-item'>
				<h3 className='popular-questions__title messages-title'>
					<span>Покажи пример</span>
				</h3>
			</div>,
			<div key='message7' className='questions-clients__sender-item'>
				<h3 className='popular-questions__title messages-title'>
					Вы оставили рекомендацию, но в тот момент у клиента не было острой
					потребности в деньгах. Спустя месяц клиенту стала актуально и он
					получил финансирование. Комиссия сервиса составила 100 000 рублей
				</h3>
			</div>,
			<div key='message8' className='questions-clients__sender-item'>
				<h3 className='popular-questions__title messages-title'>
					В зависимости от вашего уровня сумма вознаграждения будет равна
				</h3>
			</div>,
			<img
				key='message9'
				src={isMobile ? ClientsTable2Mobile : ClientsTable2}
				alt=''
				className={`questions-clients__table end-dialogue ${
					isMobile ? 'table-mobile' : 'table-desktop'
				}`}
			/>,
		]

		setMessages([])
		setMessageIndex(0)
		setNewMessages(messagesToAdd)
	}

	const handlePrizesClick = () => {
		setShowQuestions(true)

		const messagesToAdd = [
			<div key='message1' className='questions-prizes__recipient-item'>
				<h3 className='popular-prizes__title messages-title'>
					Как я получу вознаграждение?
				</h3>
			</div>,
			<div key='message2' className='questions-prizes__sender-item'>
				<h3 className='popular-prizes__title messages-title'>
					Вознаграждение поступает с 1 по 10 число текущего месяца за предыдущий
					месяц
				</h3>
			</div>,
			<div key='message3' className='questions-prizes__sender-item'>
				<h3 className='popular-prizes__title messages-title'>
					Вознаграждение приходит на банковскую карту, привязанную к номеру, по
					которому вы зарегистрированы
				</h3>
			</div>,
			<div
				key='message4'
				className='questions-prizes__sender-item last-message end-dialogue'
			>
				<h3 className='popular-prizes__title messages-title '>
					<span>Обязательно проверьте, чтобы номер был привязан к СБП</span>
				</h3>
			</div>,
		]

		setMessages([])
		setMessageIndex(0)
		setNewMessages(messagesToAdd)
	}

	const handleNoPrizesClick = () => {
		setShowQuestions(true)

		const messagesToAdd = [
			<div key='message1' className='questions-no-prizes__recipient-item'>
				<h3 className='popular-no-prizes__title messages-title'>
					В каком случае я не получу вознаграждение?
				</h3>
			</div>,
			<div key='message2' className='questions-no-prizes__sender-item'>
				<h3 className='popular-no-prizes__title messages-title'>
					Вознаграждение приходит только за целевую рекомендацию
				</h3>
			</div>,
			<div
				key='message3'
				className='questions-no-prizes__sender-item last-message end-dialogue'
			>
				<h3 className='popular-no-prizes__title messages-title '>
					В случае, если по вашей рекомендации клиент отказывается от
					финансирования, у него нет залога или он не выходит на связь — это
					рекомендация не засчитывается
				</h3>
			</div>,
		]

		setMessages([])
		setMessageIndex(0)
		setNewMessages(messagesToAdd)
	}

	useEffect(() => {
		if (newMessages.length > 0 && messageIndex === 0) {
			// Display the first message immediately
			setMessages([{ message: newMessages[0], animate: true }])
			setMessageIndex(1)
		}

		if (messageIndex > 0 && messageIndex < newMessages.length) {
			const timer = setTimeout(() => {
				setMessages(prevMessages => [
					...prevMessages,
					{ message: newMessages[messageIndex], animate: true },
				])
				setMessageIndex(prevIndex => prevIndex + 1)
			}, 2000)
			return () => clearTimeout(timer)
		}
	}, [messageIndex, newMessages])

	useEffect(() => {
		if (messagesEndRef.current && showQuestions) {
			messagesEndRef.current.parentNode.scrollTo({
				top: messagesEndRef.current.offsetTop,
				behavior: 'smooth',
			})
		}
	}, [messages, showQuestions])

	return (
		<section className='popular-questions'>
			<div className='popular-questions__background-title'>
				<div className='popular-questions__container' id='popular-questions'>
					<h1 className='popular-questions__title hero-title'>
						Популярные вопросы
					</h1>
				</div>
			</div>
			<div className='popular-questions__dialog'>
				<img
					src={QuestionsBackground}
					alt=''
					className='popular-questions__background'
				/>
				<img
					src={QuestionsBackgroundLinear}
					alt=''
					className='popular-questions__background-linear'
				/>
				<div className='popular-questions__container'>
					<div className='popular-questions__content'>
						<div className='popular-questions__header'>
							<img
								src={QuestionsLogo}
								alt=''
								className='popular-questions__logo'
							/>
							<div className='popular-questions__titles'>
								<h3 className='popular-questions__title'>TheHelp_bot</h3>
								<h3 className='popular-questions__title'>бот</h3>
							</div>
						</div>
						<div className='communication'>
							<div className='popular-questions__message'>
								{messages.map((msg, index) => (
									<div
										key={index}
										className={`popular-questions__messages ${
											showQuestions && msg.animate ? 'show animate-in' : ''
										}`}
									>
										{msg.message}
									</div>
								))}
								<div ref={messagesEndRef}></div>
							</div>
							{!showQuestions && (
								<div className='popular-questions__message-popup'>
									<button
										className='popular-questions__message-button'
										onClick={handleLevelsClick}
									>
										<span>/levels</span> Узнать подробнее про уровни
									</button>
									<button
										className='popular-questions__message-button'
										onClick={handleMoneysClick}
									>
										<span>/moneys </span> Как получить максимальное
										вознаграждение?
									</button>
									<button
										className='popular-questions__message-button'
										onClick={handleClientsClick}
									>
										<span>/clients</span> Если клиент получил деньги не сразу?
									</button>
									<button
										className='popular-questions__message-button'
										onClick={handlePrizesClick}
									>
										<span>/prizes</span> Как я получу вознаграждение?
									</button>
									<button
										className='popular-questions__message-button'
										onClick={handleNoPrizesClick}
									>
										<span>/no_prizes</span> В каком случае я не получу
										вознаграждение?
									</button>
								</div>
							)}
							<div className='popular-questions__message-input'>
								<button
									className='popular-questions__menu'
									onClick={toggleQuestions}
								>
									Меню
								</button>
								<img
									src={PaperClip}
									alt=''
									className='popular-questions__icon paperclip'
								/>
								<h3 className='popular-questions__title'>Нажмите на меню...</h3>
								<div className='popular-questions__buns'>
									<img
										src={SmileCircle}
										alt=''
										className='popular-questions__icon'
									/>
									<img
										src={CameraSquare}
										alt=''
										className='popular-questions__icon'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='popular-questions__footer'></div>
		</section>
	)
}

export default PopularQuestions
