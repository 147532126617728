import ButtonPersonalAccount from '../../buttonPersonalAccount/ButtonPersonalAccount'
import './unauthorizedDiagram.css'

import Diagram from './Group 200.svg'

const UnauthorizedDiagram = () => {
	return (
		<section className='diagram '>
			<div className='diagram__container'>
				<div className='diagram__content'>
					<ul className='diagram__list'>
						<li className='diagram__list-item'>
							<p className='diagram__explanation'>
								Проходите быструю регистрацию
							</p>
						</li>
						<li className='diagram__list-item'>
							<p className='diagram__explanation'>
								Добавляйте контакты тех, кому необходимо финансирование
							</p>
						</li>
						<li className='diagram__list-item'>
							<p className='diagram__explanation'>
								Отслеживайте статус добавленных контактов
							</p>
						</li>
						<li className='diagram__list-item'>
							<p className='diagram__explanation'>Получайте вознаграждение</p>
						</li>
						<li className='diagram__list-item'>
							<p className='diagram__explanation'>
								Участвуйте в бонусной программе
							</p>
						</li>
					</ul>
					<img src={Diagram} alt='' className='diagram__img' />
				</div>
				<ButtonPersonalAccount />
			</div>
		</section>
	)
}

export default UnauthorizedDiagram
