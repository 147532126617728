import { useState, useEffect } from 'react'

const RegularColors = [
	'hsl(139 100% 87%)',
	'hsl(139 51% 70%)',
	'hsl(196 27% 59%)',
]

const PremiumColors = [
	'hsl(0 100% 75%)',
	'hsl(61 100% 83%)',
	'hsl(137 27% 79%)',
]

const DistributionColors = (prizes, predefinedColors) => {
	let colorIndex = 0
	return prizes.map((prize, index) => {
		const color = predefinedColors[colorIndex]
		colorIndex = (colorIndex + 1) % predefinedColors.length
		return { ...prize, color }
	})
}

const fetchPrizes = () => {
	const userData = JSON.parse(localStorage.getItem('userData')) || {}
	const regularPrizes = userData.regular_prizes || []
	const premiumPrizes = userData.super_prizes || []
	return { regularPrizes, premiumPrizes }
}

const usePrizes = () => {
	const [regularPrizes, setRegularPrizes] = useState([])
	const [premiumPrizes, setPremiumPrizes] = useState([])

	useEffect(() => {
		const { regularPrizes, premiumPrizes } = fetchPrizes()

		const updatedRegularPrizes = DistributionColors(
			regularPrizes.map(prize => ({ text: prize })),
			RegularColors
		)

		const updatedPremiumPrizes = DistributionColors(
			premiumPrizes.map(prize => ({ text: prize })),
			PremiumColors
		)

		setRegularPrizes(updatedRegularPrizes)
		setPremiumPrizes(updatedPremiumPrizes)
	}, [])

	return { regularPrizes, premiumPrizes }
}

export { usePrizes }
