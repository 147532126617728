import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './accrual.css'

const AccrualHistory = () => {
	const [accrualList, setAccrualList] = useState([])

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const phone = localStorage.getItem('userPhone')
				if (phone) {
					const timestamp = Date.now()
					const response = await axios.post(
						process.env.REACT_APP_HOST + `/data/user?timestamp=${timestamp}`,
						{ phone },
						{
							auth: {
								username: process.env.REACT_APP_LOGIN,
								password: process.env.REACT_APP_PASSWORD,
							},
						}
					)
					const userData = response.data
					localStorage.setItem('userData', JSON.stringify(userData))
					if (userData && userData.accruals) {
						const accruals = userData.accruals
						const formattedAccrual = accruals.map(accrual => ({
							date: new Date(accrual.created_at).toLocaleDateString(),
							amount: accrual.amount,
							comment: accrual.comment,
						}))
						setAccrualList(formattedAccrual)
					}
				}
			} catch (error) {
				console.error('Error fetching user data:', error)
			}
		}

		fetchUserData()
	}, [])

	return (
		<section className='accrual'>
			<div className='accrual__container'>
				<h1 className='accrual__title'>Начисления</h1>
				<h3 className='accrual__title'>
					Здесь вы сможете отслеживать выплаты, которые получали
				</h3>
				<div className='accrual__content'>
					<div className='accrual__wrapper'>
						<ul className='accrual__list prize-wrapper'>
							<li className='accrual__list-item orders-right accrual__titles'>
								Дата
							</li>
							<li className='accrual__list-item accrual__titles'>
								Начисление и комментарий
							</li>
						</ul>
						{accrualList.map((accrual, index) => (
							<ul key={index} className='accrual__list prize-wrapper'>
								<li className='accrual__list-item orders orders-right orders-right'>
									{accrual.date}
								</li>
								<div>
									<li
										className='accrual__list-item orders'
										style={{
											color: accrual.amount > 0 ? 'green' : 'red',
										}}
									>
										{accrual.amount}
									</li>
									{accrual.comment && (
										<li className='accrual__list-item'>
											<p className='accrual__comment'>{accrual.comment}</p>
										</li>
									)}
								</div>
							</ul>
						))}
					</div>
				</div>
			</div>
		</section>
	)
}

export default AccrualHistory
