import './buttonmainsite.css'

const ButtonMainSite = () => {
	return (
		<a
			href='https://themezex.com/'
			target='_blank'
			rel='noopener'
			className='buttonmainsite-link'
		>
			<button className='buttonmainsite'>Перейти на сайт платформы</button>
		</a>
	)
}

export default ButtonMainSite
