import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import HelpInfo from '../components/helpInfo/HelpInfo'
import FooterHelp from '../components/footerHelp/FooterHelp'
import Footer from '../components/footer/Footer'

const Help = () => {
	const navigate = useNavigate()

	useEffect(() => {
		// Проверка авторизации при загрузке компонента
		const loggedIn = localStorage.getItem('isLoggedIn')
		if (!loggedIn) {
			// Если пользователь не авторизован, перенаправляем его на главную страницу
			navigate('/')
		}
	}, [navigate])

	return (
		<>
			<HelpInfo />
			<FooterHelp />
			<Footer />
		</>
	)
}

export default Help
