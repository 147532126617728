import React from 'react'
import axios from 'axios'
import './buttonrequestreport.css'

const ButtonRequestReport = () => {
	const fetchData = async () => {
		const userData = JSON.parse(localStorage.getItem('userData'))
		if (userData) {
			const { user_mail } = userData
			if (!user_mail) {
				alert('Пожалуйста, введите почту в профиле')
			} else {
				try {
					const response = await axios.post(
						process.env.REACT_APP_HOST + '/add/report_request',
						{
							phone: localStorage.getItem('userPhone'),
						},
						{
							auth: {
								username: process.env.REACT_APP_LOGIN,
								password: process.env.REACT_APP_PASSWORD,
							},
						}
					)
					alert('Запрос успешно отправлен')
				} catch (error) {
					alert('Ошибка при отправке запроса')
				}
			}
		}
	}

	const handleClick = () => {
		fetchData()
	}

	return (
		<>
			<div className='buttonrequestreport__container'>
				<button className='buttonrequestreport' onClick={handleClick}>
					Запросить отчет
				</button>
			</div>
		</>
	)
}

export default ButtonRequestReport
