import React, { useEffect, useState } from 'react'
import './ranksystem.css'

const RankSystem = () => {
	const [userData, setUserData] = useState(null)
	const [currentRankLevel, setCurrentRankLevel] = useState(0)
	const [currentRank, setCurrentRank] = useState('')
	const [nextRank, setNextRank] = useState('')
	const [dealsToNextRank, setDealsToNextRank] = useState(0)
	const [dealsToLastRank, setDealsToLastRank] = useState(0)
	const [progressWidth, setProgressWidth] = useState(0)

	useEffect(() => {
		const storedUserData = localStorage.getItem('userData')
		if (storedUserData) {
			const userData = JSON.parse(storedUserData)
			setUserData(userData)

			const currentRankTitle = userData.level_title
			const currentRankLevel = userData.count_rec_level
			setCurrentRankLevel(currentRankLevel)

			const nextRankTitle = (() => {
				switch (currentRankTitle) {
					case 'M':
						return 'ME'
					case 'ME':
						return 'MEZ'
					case 'MEZ':
						return 'MEZE'
					case 'MEZE':
						return 'MEZEX'
					case 'MEZEX':
						return 'MAX'
					default:
						return ''
				}
			})()

			setCurrentRank(rankDescription(currentRankTitle))
			setNextRank(rankDescription(nextRankTitle))

			const dealsToNextRank = (() => {
				switch (currentRankTitle) {
					case 'M':
						return 50
					case 'ME':
						return 100
					case 'MEZ':
						return 150
					case 'MEZE':
						return 'Топ 3'
					case 'MEZEX':
						return '∞'
					default:
						return 0
				}
			})()

			setDealsToNextRank(dealsToNextRank)

			const dealsToLastRank = (() => {
				switch (currentRankTitle) {
					case 'M':
						return 0
					case 'ME':
						return 50
					case 'MEZ':
						return 100
					case 'MEZE':
						return 150
					case 'MEZEX':
						return 150
					default:
						return 0
				}
			})()

			setDealsToLastRank(dealsToLastRank)

			// Вычисляем ширину прогресс-бара
			const calculateProgressWidth = () => {
				if (currentRankLevel > 150) {
					return 100
				} else if (
					typeof dealsToNextRank === 'number' &&
					typeof dealsToLastRank === 'number' &&
					dealsToNextRank !== 0
				) {
					return (
						((currentRankLevel - dealsToLastRank) /
							(dealsToNextRank - dealsToLastRank)) *
						100
					)
				} else {
					return 0
				}
			}

			setProgressWidth(calculateProgressWidth())
		}
	}, [])

	const rankDescription = rankTitle => {
		switch (rankTitle) {
			case 'M':
				return (
					<>
						<span className='Rank-black'>M</span>
						<span className='Rank-grey'>E</span>
						<span className='Rank-grey'>Z</span>
						<span className='Rank-grey'>E</span>
						<span className='Rank-grey'>X</span>
					</>
				)

			case 'ME':
				return (
					<>
						<span className='Rank-black'>M</span>
						<span className='Rank-black'>E</span>
						<span className='Rank-grey'>Z</span>
						<span className='Rank-grey'>E</span>
						<span className='Rank-grey'>X</span>
					</>
				)
			case 'MEZ':
				return (
					<>
						<span className='Rank-black'>M</span>
						<span className='Rank-black'>E</span>
						<span className='Rank-black'>Z</span>
						<span className='Rank-grey'>E</span>
						<span className='Rank-grey'>X</span>
					</>
				)
			case 'MEZE':
				return (
					<>
						<span className='Rank-black'>M</span>
						<span className='Rank-black'>E</span>
						<span className='Rank-black'>Z</span>
						<span className='Rank-black'>E</span>
						<span className='Rank-grey'>X</span>
					</>
				)
			case 'MEZEX':
				return (
					<>
						<span className='Rank-black'>M</span>
						<span className='Rank-black'>E</span>
						<span className='Rank-black'>Z</span>
						<span className='Rank-black'>E</span>
						<span className='Rank-black'>X</span>
					</>
				)
			case 'MAX':
				return 'MAX'
			default:
				return ''
		}
	}

	return (
		<section className='rank-system'>
			<div className='rank-system__level'>
				<p className='rank-system__desc'>{currentRank}</p>
				<p className='rank-system__desc grey'>До уровня {nextRank}</p>
			</div>
			<div className='progress-bar'>
				<div
					className='progress-bar__inner'
					style={{
						width: `${progressWidth}%`,
					}}
				></div>
			</div>
			<div className='rank-system__numbers'>
				<p className='rank-system__desc'>{dealsToLastRank} рекомендаций</p>
				<p className='rank-system__desc'>
					{currentRankLevel}/{dealsToNextRank} рекомендаций
				</p>
			</div>
		</section>
	)
}

export default RankSystem
