import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import UnauthorizedMain from './pages/UnauthorizedMain'
import Applications from './pages/Applications'
import ProfilePrizes from './pages/ProfilePrizes'
import Accruals from './pages/Accruals'
import Help from './pages/Help'
import Header from '../src/components/header/Header'
import ScrollToTop from './utils/ScrollToTop'
import './styles/main.css'

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(false)

	useEffect(() => {
		const loggedIn = localStorage.getItem('isLoggedIn')
		if (loggedIn) {
			setIsLoggedIn(true) // Установка состояния isLoggedIn в true, если ключ найден
		}
	}, []) // Этот эффект должен запускаться только при монтировании компонента

	useEffect(() => {
		if (isLoggedIn) {
			const redirectPath = localStorage.getItem('redirectPath')
			if (redirectPath) {
				localStorage.removeItem('redirectPath')
				window.history.replaceState(null, '', redirectPath)
			}
		}
	}, [isLoggedIn])

	return (
		<Router>
			<ScrollToTop />
			<div className='app'>
				<Header />
				<div className='content'>
					<Routes>
						<Route path='/' element={<UnauthorizedMain />} />
						<Route path='/application' element={<Applications />} />
						<Route path='/level-and-bonuses' element={<ProfilePrizes />} />
						<Route path='/accruals' element={<Accruals />} />
						<Route path='/help' element={<Help />} />
					</Routes>
				</div>
			</div>
		</Router>
	)
}

export default App
