import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './settings.css'

const Settings = ({ setShowSettings, onClose }) => {
	const [phone, setPhone] = useState('')
	const [mail, setMail] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [name, setName] = useState('')
	const [surname, setSurname] = useState('')
	const [nickname, setNickname] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [error, setError] = useState('')

	useEffect(() => {
		const userData = JSON.parse(localStorage.getItem('userData'))
		if (userData) {
			setPhone(userData.user_phone)
		}
	}, [])

	const handleChangePassword = async () => {
		if (newPassword !== confirmPassword) {
			setError('Пароли не совпадают')
			return
		}

		try {
			await axios.post(
				process.env.REACT_APP_HOST + '/data/update/password',
				{
					phone,
					new_password: newPassword,
				},
				{
					auth: {
						username: process.env.REACT_APP_LOGIN,
						password: process.env.REACT_APP_PASSWORD,
					},
				}
			)
			setError('')
		} catch (error) {
			console.error('Error changing password:', error)
			setError('Не удалось изменить пароль. Пожалуйста, попробуйте еще раз.')
		}
	}

	const handleChangeName = async () => {
		if (!name || !surname) {
			return
		}

		try {
			await axios.post(
				process.env.REACT_APP_HOST + '/data/update/user_name',
				{
					phone,
					new_name: `${name} ${surname}`,
				},
				{
					auth: {
						username: process.env.REACT_APP_LOGIN,
						password: process.env.REACT_APP_PASSWORD,
					},
				}
			)
		} catch (error) {
			console.error('Error changing name:', error)
		}
	}

	const handleChangeNickname = async () => {
		if (!nickname) {
			return
		}

		try {
			await axios.post(
				process.env.REACT_APP_HOST + '/data/update/nickname',
				{
					phone,
					new_nick: nickname,
				},
				{
					auth: {
						username: process.env.REACT_APP_LOGIN,
						password: process.env.REACT_APP_PASSWORD,
					},
				}
			)
		} catch (error) {
			console.error('Error changing nickname:', error)
		}
	}

	const handleChangeEmail = async () => {
		if (!mail) {
			return
		}

		try {
			await axios.post(
				process.env.REACT_APP_HOST + '/reg/add/user_mail',
				{
					phone,
					mail,
				},
				{
					auth: {
						username: process.env.REACT_APP_LOGIN,
						password: process.env.REACT_APP_PASSWORD,
					},
				}
			)
			setError('')
		} catch (error) {
			console.error('Error changing email:', error)
			setError(
				'Не удалось изменить адрес электронной почты. Пожалуйста, попробуйте еще раз.'
			)
		}
	}

	const handleSubmit = async () => {
		const hasMatchingPasswords = newPassword.trim() === confirmPassword.trim()

		if (newPassword.trim() && confirmPassword.trim() && !hasMatchingPasswords) {
			alert('Пароли не совпадают')
			return
		}

		const promises = []

		// Проверяем, изменились ли поля "Имя" или "Фамилия"
		const userData = JSON.parse(localStorage.getItem('userData'))
		const [initialName, initialSurname] = userData.user_name.split(' ')

		if (
			(name.trim() && name.trim() !== initialName) ||
			(surname.trim() && surname.trim() !== initialSurname)
		) {
			if (!name.trim() || !surname.trim()) {
				alert('Заполните поля "Имя" и "Фамилия"')
				return
			}
			promises.push(handleChangeName())
		}

		if (mail.trim()) {
			promises.push(handleChangeEmail())
		}

		if (nickname.trim()) {
			promises.push(handleChangeNickname())
		}

		if (newPassword.trim() && confirmPassword.trim()) {
			promises.push(handleChangePassword())
		}

		await Promise.all(promises)

		// Если нет ошибок, закрываем окно настроек
		if (!error) {
			setShowSettings(false)
			window.location.reload()
		}
	}

	const handleClose = e => {
		e.stopPropagation()
		onClose() // Вызываем функцию onClose переданную из родительского компонента
	}

	useEffect(() => {
		// Получаем данные из локального хранилища
		const userData = JSON.parse(localStorage.getItem('userData'))
		if (userData) {
			// Разделяем имя и фамилию
			const [firstName, lastName] = userData.user_name.split(' ')
			setName(firstName || '') // Имя
			setSurname(lastName || '') // Фамилия
			setNickname(userData.nickname || '') // Никнейм
			setMail(userData.user_mail || '') // Email
		}
	}, [])

	return (
		<>
			<div className='popup-settings'>
				<div className='settings'>
					<div className='close-button' onClick={handleClose}>
						<div className='line1'></div>
						<div className='line2'></div>
					</div>
					<div className='settings-phone'>
						<p className='settings-phone__desc'>Телефон</p>
						<p className='settings-phone__desc-mobile'>{phone}</p>
						<input
							type='phone'
							className='settings-input settings-input__phone'
							placeholder={phone}
							readOnly
						/>
					</div>
					<div className='settings-name'>
						<p className='settings-name__desc'>Имя</p>
						<input
							type='text'
							className='settings-input'
							placeholder={name}
							onChange={e => setName(e.target.value)}
						/>
					</div>
					<div className='settings-surname'>
						<p className='settings-surname__desc'>Фамилия</p>
						<input
							type='text'
							className='settings-input'
							placeholder={surname}
							onChange={e => setSurname(e.target.value)}
						/>
					</div>
					<div className='settings-nickname'>
						<p className='settings-nickname__desc'>Никнейм</p>
						<input
							type='text'
							className='settings-input'
							placeholder={nickname}
							onChange={e => setNickname(e.target.value)}
						/>
					</div>
					<div className='settings-email'>
						<p className='settings-email__desc'>Email</p>
						<input
							type='email'
							className='settings-input'
							placeholder={mail}
							onChange={e => setMail(e.target.value)}
						/>
					</div>
					<div className='settings-password'>
						<p className='settings-password__desc'>Новый пароль</p>
						<input
							type='password'
							className='settings-input'
							value={newPassword}
							onChange={e => setNewPassword(e.target.value)}
						/>
					</div>
					<div className='settings-password-correct'>
						<p className='settings-password-correct__desc'>
							Подтверждение пароля
						</p>
						<input
							type='password'
							className='settings-input'
							value={confirmPassword}
							onChange={e => setConfirmPassword(e.target.value)}
						/>
					</div>
					{error && <p className='error-message'>{error}</p>}
				</div>
				<button className='settings-button' onClick={handleSubmit}>
					Сохранить изменения
				</button>
			</div>
		</>
	)
}

export default Settings
