import React, { useState, useEffect } from 'react'
import './applicationpopup.css'
import ApplicationPopupImg from './popup-application.svg'
import ApplicationPopupImgMobile from './popup-application-mobile.svg'

const ApplicationPopup = ({ onClose }) => {
	const [isMobile, setIsMobile] = useState(false)

	const handleClose = e => {
		e.stopPropagation()
		onClose()
	}

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 1024)
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return (
		<div className='popup-background'>
			<div className='application-popup'>
				<div className='close-button close-button-popup' onClick={handleClose}>
					<div className='line1'></div>
					<div className='line2'></div>
				</div>
				<img
					src={isMobile ? ApplicationPopupImgMobile : ApplicationPopupImg}
					className='application-popup-img'
				/>
				<button
					type='submit'
					className='application-popup-button'
					onClick={handleClose}
				>
					Ок
				</button>
			</div>
		</div>
	)
}

export default ApplicationPopup
