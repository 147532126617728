import React from 'react'
import UnauthorizedHero from '../components/unauthorizedBlocks/unauthorizedHero/UnauthorizedHero'
import AboutUs from '../components/aboutUs/AboutUs'
import UnauthorizedEarnings from '../components/unauthorizedBlocks/unauthorizedEarnings/UnauthorizedEarnings'
import UnauthorizedDiagram from '../components/unauthorizedBlocks/unauthorizedDiagram/UnauthorizedDiagram'
import UnauthorizedBonusProgram from '../components/unauthorizedBlocks/unauthorizedBonusProgram/UnauthorizedBonusProgram'
import Contacts from '../components/contacts/Contacts'
import Footer from '../components/footer/Footer'

const UnauthorizedMain = () => {
	return (
		<>
			<UnauthorizedHero />
			<AboutUs />
			<UnauthorizedEarnings />
			<UnauthorizedDiagram />
			<UnauthorizedBonusProgram />
			<Contacts />
			<Footer />
		</>
	)
}

export default UnauthorizedMain
