import './footerhelp.css'

const FooterHelp = () => {
	return (
		<section className='footer-help'>
			<div className='footer-help-background'>
				<div className='footer-help__container'>
					<ul className='footer-help__list footer-help__products'>
						<li className='footer-help__list-item'>
							<p className='footer-help__desc footer-help__desc-title'>
								Продукты
							</p>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://finccon.ru/?utm_source=themezex'
								target='_blank'
								className='footer-help__desc'
							>
								Финансирование для бизнеса
							</a>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://ofin24.ru/?utm_source=themezex'
								target='_blank'
								className='footer-help__desc'
							>
								Финансирование для физлиц
							</a>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://themezex.com/invest?utm_source=themezex'
								target='_blank'
								className='footer-help__desc'
							>
								Сервис для кредиторов
							</a>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://price.themezex.com/?utm_source=themezex'
								target='_blank'
								className='footer-help__desc'
							>
								Сервис для оценки авто
							</a>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://rwmezex.ru/?utm_source=themezex'
								target='_blank'
								className='footer-help__desc'
							>
								Сервис для учета рабочего времени
							</a>
						</li>
					</ul>

					<ul className='footer-help__list footer-help__telegram-bots'>
						<li className='footer-help__list-item'>
							<p className='footer-help__desc footer-help__desc-title'>
								Телеграм-боты
							</p>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://t.me/CustomerTheMezEx_bot?start=theMezEXcom'
								target='_blank'
								className='footer-help__desc'
							>
								Бот для получения финансирования и проверки имущества
							</a>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://t.me/TheProfInvest_bot?start=themezexcom'
								target='_blank'
								className='footer-help__desc'
							>
								Бот для кредиторов
							</a>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://t.me/TheLead_MZX_bot'
								target='_blank'
								className='footer-help__desc'
							>
								Бот для рекомендаций
							</a>
						</li>
					</ul>

					<ul className='footer-help__list footer-help__company'>
						<li className='footer-help__list-item'>
							<p className='footer-help__desc footer-help__desc-title'>
								Компания
							</p>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://themezex.com/'
								target='_blank'
								className='footer-help__desc'
							>
								Официальный сайт компании MezEx
							</a>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://vk.com/themezex'
								target='_blank'
								className='footer-help__desc'
							>
								Группа ВКонтакте
							</a>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://themezex.com/documents'
								target='_blank'
								className='footer-help__desc'
							>
								Свидетельства
							</a>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://documents.themezex.com/'
								target='_blank'
								className='footer-help__desc'
							>
								Документация
							</a>
						</li>
					</ul>

					<ul className='footer-help__list footer-help__news'>
						<li className='footer-help__list-item'>
							<p className='footer-help__desc footer-help__desc-title'>
								Новостные ресурсы
							</p>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://t.me/+0cBbYtmZjG02OWUy'
								target='_blank'
								className='footer-help__desc'
							>
								Телеграм-канал
							</a>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://dzen.ru/only_finance_news'
								target='_blank'
								className='footer-help__desc'
							>
								Канал в Дзене
							</a>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://www.youtube.com/@only_finance_news'
								target='_blank'
								className='footer-help__desc'
							>
								Ютуб{' '}
							</a>
						</li>
						<li className='footer-help__list-item'>
							<a
								href='https://themezex.com/blog'
								target='_blank'
								className='footer-help__desc'
							>
								Блог MezEx
							</a>
						</li>
					</ul>

					<ul className='footer-help__list footer-help__contacts'>
						<li className='footer-help__list-item'>
							<p className='footer-help__desc footer-help__desc-title'>
								Контакты
							</p>
						</li>
						<li className='footer-help__list-item'>
							<p className='footer-help__desc'>Отдел по работе с клиентами</p>
						</li>
						<li className='footer-help__list-item'>
							<a href='tel:83422770075' className='footer-help__desc'>
								8 (342) 277-00-75
							</a>
						</li>
						<li className='footer-help__list-item'>
							<p className='footer-help__desc'>По общим вопросам</p>
						</li>
						<li className='footer-help__list-item'>
							<a href='tel:83422592773' className='footer-help__desc'>
								8 (342) 259-27-73
							</a>
						</li>
						<li className='footer-help__list-item'>
							<a href='mailto:themezex@gmail.com' className='footer-help__desc'>
								themezex@gmail.com
							</a>
						</li>
					</ul>
				</div>
			</div>
		</section>
	)
}

export default FooterHelp
