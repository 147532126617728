import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { usePrizes } from '../../helpers/prizes'
import './wheeloffortune.css'

import LegWheel from './Колёсико_ножка.svg'
import FrameWheel from './Колёсико_рамка.svg'
import WheelTicketInfo from './../../img/icons/ApplicationInfo.svg'
import WheelLeft from './wheel-img-left.svg'
import WheelRight from './wheel-img-right.svg'

const WheelOfFortune = ({
	setPremiumTickets,
	setIsPremiumWheel,
	isPremiumWheel,
	togglePremiumWheel,
}) => {
	const [isSpinning, setIsSpinning] = useState(false)
	const [prizes, setPrizes] = useState([])
	const [normalTicketCount, setNormalTicketCount] = useState(0)
	const [premiumTicketCount, setPremiumTicketCount] = useState(0)
	const [showWheelTicket, setShowWheelTicket] = useState(false)
	const [spinId, setSpinId] = useState(null)
	const [isBlocked, setIsBlocked] = useState(false)
	const { regularPrizes, premiumPrizes } = usePrizes()
	const [showPrizePopup, setShowPrizePopup] = useState(false)
	const [selectedPrizeText, setSelectedPrizeText] = useState('')

	const toggleWheelTicket = () => {
		setShowWheelTicket(prevShow => !prevShow)
	}

	useEffect(() => {
		const loadUserDataFromLocalStorage = () => {
			const userData = JSON.parse(localStorage.getItem('userData'))
			if (userData) {
				setNormalTicketCount(userData.number_of_spins)
				setPremiumTicketCount(userData.number_of_super)
			}
		}

		loadUserDataFromLocalStorage()
	}, [])

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const phone = localStorage.getItem('userPhone')
				if (phone) {
					const timestamp = Date.now()
					const response = await axios.post(
						`${process.env.REACT_APP_HOST}/data/user?timestamp=${timestamp}`,
						{ phone },
						{
							auth: {
								username: process.env.REACT_APP_LOGIN,
								password: process.env.REACT_APP_PASSWORD,
							},
						}
					)

					setNormalTicketCount(response.data.number_of_spins)
					setPremiumTicketCount(response.data.number_of_super)
					localStorage.setItem('userData', JSON.stringify(response.data))
				}
			} catch (error) {
				console.error('Error fetching user data:', error)
			}
		}

		setIsPremiumWheel(false)
		setPrizes(regularPrizes)
		fetchUserData()
	}, [regularPrizes])

	useEffect(() => {
		const wheel = document.querySelector('.deal-wheel')
		const spinner = wheel.querySelector('.spinner')
		const trigger = wheel.querySelector('.btn-spin')
		const ticker = wheel.querySelector('.ticker')
		const toggleLeftButton = wheel.querySelector('.btn-toggle-left')
		const toggleRightButton = wheel.querySelector('.btn-toggle-right')

		const prizeSlice = 360 / prizes.length
		const prizeOffset = Math.floor(180 / prizes.length)
		const spinClass = 'is-spinning'
		const selectedClass = 'selected'
		const spinnerStyles = window.getComputedStyle(spinner)

		let tickerAnim
		let rotation = 0
		let currentSlice = 0
		let prizeNodes

		const createPrizeNodes = () => {
			spinner.innerHTML = ''
			prizes.forEach(({ text, color, reaction }, i) => {
				const rotation = prizeSlice * i * -1 - prizeOffset
				spinner.insertAdjacentHTML(
					'beforeend',
					`<li class="prize" data-reaction=${reaction} data-spin-id="" style="--rotate: ${rotation}deg">
            <span class="text">${text}</span>
          </li>`
				)
			})
			prizeNodes = wheel.querySelectorAll('.prize')
		}

		const createConicGradient = () => {
			const gradientColors = prizes
				.map(
					({ color }, i) =>
						`${color} ${(100 / prizes.length) * i}% ${
							(100 / prizes.length) * (i + 1)
						}%`
				)
				.join(', ')

			spinner.style.background = `conic-gradient(from -90deg, ${gradientColors})`
		}

		const setupWheel = () => {
			createConicGradient()
			createPrizeNodes()
		}

		const spinertia = (min, max) => {
			min = Math.ceil(min)
			max = Math.floor(max)
			return Math.floor(Math.random() * (max - min + 1)) + min
		}

		const runTickerAnimation = () => {
			const values = spinnerStyles.transform
				.split('(')[1]
				.split(')')[0]
				.split(',')
			const a = values[0]
			const b = values[1]
			let rad = Math.atan2(b, a)

			if (rad < 0) rad += 2 * Math.PI

			const angle = Math.round(rad * (180 / Math.PI))
			const slice = Math.floor(angle / prizeSlice)

			if (currentSlice !== slice) {
				ticker.style.animation = 'none'
				setTimeout(() => (ticker.style.animation = null), 10)
				currentSlice = slice
			}
			tickerAnim = requestAnimationFrame(runTickerAnimation)
		}

		const selectPrize = spinId => {
			const selected = Math.floor(rotation / prizeSlice)
			prizeNodes[selected].classList.add(selectedClass)
			const selectedPrize = prizes[selected]
			setSelectedPrizeText(selectedPrize.text)
			setShowPrizePopup(true)
			sendPrize(spinId, selectedPrize) // Отправляем выбранный приз по API
		}

		const sendPrize = async (spinId, selectedPrize) => {
			const phone = localStorage.getItem('userPhone')

			if (phone) {
				const wheelType = isPremiumWheel ? 'super' : 'spin'

				try {
					await axios.post(
						`${process.env.REACT_APP_HOST}/data/add/prizes`,
						{
							phone,
							prize: selectedPrize.text,
							wheel: wheelType,
							spin_id: spinId,
						},
						{
							auth: {
								username: process.env.REACT_APP_LOGIN,
								password: process.env.REACT_APP_PASSWORD,
							},
						}
					)
				} catch (error) {
					console.error('Ошибка сети:', error)
				}
			} else {
				console.error('Данные пользователя не найдены в локальном хранилище')
			}
		}

		const handleSpin = async () => {
			if (
				((isPremiumWheel && premiumTicketCount > 0) ||
					(!isPremiumWheel && normalTicketCount > 0)) &&
				!isSpinning
			) {
				document.querySelectorAll('a').forEach(link => {
					link.classList.add('blocked-links')
				})
				trigger.disabled = true
				if (toggleLeftButton) toggleLeftButton.disabled = true
				if (toggleRightButton) toggleRightButton.disabled = true
				setIsBlocked(true)
				setShowPrizePopup(false)

				try {
					const phone = localStorage.getItem('userPhone')
					const wheelType = isPremiumWheel ? 'super' : 'spin'

					const spinResponse = await axios.post(
						`${process.env.REACT_APP_HOST}/data/update/spins`,
						{ phone, wheel: wheelType },
						{
							auth: {
								username: process.env.REACT_APP_LOGIN,
								password: process.env.REACT_APP_PASSWORD,
							},
						}
					)

					const newSpinId = spinResponse.data.spin_id
					setSpinId(newSpinId)
					localStorage.setItem('newSpinId', newSpinId)

					setIsSpinning(true)
					setNormalTicketCount(spinResponse.data.number_of_spin)
					setPremiumTicketCount(spinResponse.data.number_of_super)

					rotation = Math.floor(Math.random() * 360 + spinertia(2000, 5000))
					prizeNodes.forEach(prize => prize.classList.remove(selectedClass))
					wheel.classList.add(spinClass)
					spinner.style.setProperty('--rotate', rotation)
					ticker.style.animation = 'none'
					runTickerAnimation()
				} catch (error) {
					console.error('Ошибка при вычитании количества билетов:', error)
				} finally {
					setIsSpinning(false)
				}
			}
		}

		const handleTransitionEnd = () => {
			cancelAnimationFrame(tickerAnim)
			rotation %= 360

			const newId = JSON.parse(localStorage.getItem('newSpinId'))
			selectPrize(newId)
			wheel.classList.remove(spinClass)
			spinner.style.setProperty('--rotate', rotation)
			trigger.disabled = false
			if (toggleLeftButton) toggleLeftButton.disabled = false
			if (toggleRightButton) toggleRightButton.disabled = false
			setIsBlocked(false)
			document.querySelectorAll('a').forEach(link => {
				link.classList.remove('blocked-links')
			})
		}

		setupWheel()

		trigger.addEventListener('click', handleSpin)
		spinner.addEventListener('transitionend', handleTransitionEnd)

		return () => {
			trigger.removeEventListener('click', handleSpin)
			spinner.removeEventListener('transitionend', handleTransitionEnd)
		}
	}, [isSpinning, prizes, isPremiumWheel])

	const toggleWheel = () => {
		togglePremiumWheel()
		setPrizes(prevState =>
			prevState === regularPrizes ? premiumPrizes : regularPrizes
		)
	}

	useEffect(() => {
		if (showWheelTicket) {
			const timeoutId = setTimeout(() => {
				document.querySelector('.wheel-info__text').classList.add('show')
			}, 100)
			return () => clearTimeout(timeoutId)
		}
	}, [showWheelTicket])

	useEffect(() => {
		if (showWheelTicket) {
			const handleClickOutside = event => {
				if (
					!event.target.closest('.wheel-ticket-info__img') &&
					!event.target.closest('.wheel-info__text')
				) {
					setShowWheelTicket(false)
				}
			}
			document.addEventListener('click', handleClickOutside)

			return () => {
				document.removeEventListener('click', handleClickOutside)
			}
		}
	}, [showWheelTicket])

	useEffect(() => {
		if (showPrizePopup) {
			const timeoutId = setTimeout(() => {
				document.querySelector('.prize-position').classList.add('show')
			}, 100)
			return () => clearTimeout(timeoutId)
		}
	}, [showPrizePopup])

	const prizePopup = () => (
		<div className='prize-position'>
			<img src={WheelRight} alt='' className='wheel-img wheel-img-right' />
			<img src={WheelLeft} alt='' className='wheel-img wheel-img-left' />
			<div className='wheel-prize-popup-wrapper'>
				<div className='wheel-prize-popup'>
					<h3 className='wheel-prize-popup__title'>Поздравляем с выигрышем!</h3>
					<p className='wheel-prize-popup__prizes'>{selectedPrizeText}</p>
					<div className='wheel-prize-popup__footnote'>
						<div className='wheel-line'></div>
						<p className='wheel-prize-popup__desc'>
							Для подробностей получения подарка с Вами свяжется специалист!
						</p>
					</div>
					<div
						className='close-button-wheel'
						onClick={() => setShowPrizePopup(false)}
					>
						<div className='line1-wheel'></div>
						<div className='line2-wheel'></div>
					</div>
				</div>
			</div>
		</div>
	)

	return (
		<div className={`deal-wheel ${isBlocked ? 'blocked' : ''}`}>
			{!isPremiumWheel && (
				<button
					className='btn-toggle-right'
					onClick={toggleWheel}
					disabled={isSpinning}
				></button>
			)}
			<div className='ticker' />
			<img src={FrameWheel} alt='' className='FrameWheel' />
			<div className='spinner' />

			<img src={LegWheel} alt='' className='LegWheel' />
			{isPremiumWheel && (
				<button
					className='btn-toggle-left'
					onClick={toggleWheel}
					disabled={isSpinning}
				></button>
			)}
			<div className='wheel-info'>
				<div
					className={`wheel-of-fortune__ticket ${
						isPremiumWheel ? 'premium_color' : 'standard-color'
					}`}
				>
					<h3 className='wheel-of-fortune__title'>
						<span>Билеты удачи:</span>
					</h3>
					<h3
						className={
							isPremiumWheel
								? 'wheel-of-fortune__ticket-premium-count'
								: 'wheel-of-fortune__ticket-count'
						}
					>
						{isPremiumWheel ? premiumTicketCount : normalTicketCount}
					</h3>
				</div>
				<div className='wheel-ticket-info'>
					<img
						src={WheelTicketInfo}
						alt=''
						className='wheel-ticket-info__img'
						onClick={toggleWheelTicket}
					/>
					<button className='btn-spin' disabled={isSpinning}>
						Крутить!
					</button>
				</div>
			</div>
			{showWheelTicket && (
				<div className='wheel-info__text'>
					<p className='wheel-info__explanation'>
						Для прокрутки колеса вам необходимо получить <span> билеты.</span>
						<span>
							<br /> Один
						</span>{' '}
						билет равен <span>одному</span> спину колеса.
					</p>
					<div>
						<p className='wheel-info__explanation'>
							<span>Они даются в следующих случаях:</span>
						</p>
						<ul className='wheel-info__list'>
							<li className='wheel-info__list-item'>
								За переход на новый уровень
							</li>
							<li className='wheel-info__list-item'>
								За каждые 50 рекомендаций
							</li>
							<li className='wheel-info__list-item'>
								За вхождение в десятку лучших рекомендателей
							</li>
						</ul>
					</div>
					<p className='wheel-info__explanation'>
						При переходе на уровень — Амбассадор MezEx, вы активируете{' '}
						<span>премиальное колесо удачи.</span>
					</p>
				</div>
			)}
			{showPrizePopup && prizePopup()}
		</div>
	)
}

export default WheelOfFortune
