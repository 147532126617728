import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './prizehistory.css'

const PrizeHistory = () => {
	const [prizeList, setPrizeList] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [totalPage, setTotalPage] = useState(1)

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const phone = localStorage.getItem('userPhone')
				if (phone) {
					const timestamp = Date.now()
					const response = await axios.post(
						process.env.REACT_APP_HOST + `/data/user?timestamp=${timestamp}`,
						{ phone },
						{
							auth: {
								username: process.env.REACT_APP_LOGIN,
								password: process.env.REACT_APP_PASSWORD,
							},
						}
					)
					const userData = response.data
					localStorage.setItem('userData', JSON.stringify(userData))
					if (userData && userData.prizes) {
						const prizes = userData.prizes
						const prizesArray = Object.values(prizes)
						const formattedPrizes = prizesArray.map(prize => ({
							date: new Date(prize.created_at).toLocaleDateString(),
							prize: prize.prize,
						}))
						setPrizeList(formattedPrizes)
						setTotalPage(Math.ceil(formattedPrizes.length / 10) || 1)
					}
				}
			} catch (error) {
				console.error('Error fetching user data:', error)
			}
		}

		fetchUserData()
	}, [])

	const handlePageChange = page => {
		if (page > 0 && page <= totalPage) {
			setCurrentPage(page)
		}
	}

	const renderPagination = () => {
		const maxPagesToShow = 3
		const pages = []

		pages.push(
			<button
				className={`pagination__button pagination__button-left ${
					currentPage === 1 ? 'disabled' : ''
				}`}
				onClick={() => handlePageChange(currentPage - 1)}
				disabled={currentPage === 1}
				key='prev'
			></button>
		)

		const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2))
		const endPage = Math.min(
			totalPage,
			currentPage + Math.floor(maxPagesToShow / 2)
		)

		for (let i = startPage; i <= endPage; i++) {
			pages.push(
				<button
					key={i}
					className={`pagination__button ${
						currentPage === i ? 'pagination__button-active' : ''
					}`}
					onClick={() => handlePageChange(i)}
				>
					{i}
				</button>
			)
		}

		pages.push(
			<button
				className={`pagination__button pagination__button-right ${
					currentPage === totalPage ? 'disabled' : ''
				}`}
				onClick={() => handlePageChange(currentPage + 1)}
				disabled={currentPage === totalPage}
				key='next'
			></button>
		)

		return <div className='pagination'>{pages}</div>
	}

	const getPaginatedData = () => {
		const startIndex = (currentPage - 1) * 10
		const endIndex = startIndex + 10
		return prizeList.slice(startIndex, endIndex)
	}

	return (
		<section className='prize-history'>
			<div className='prize-history__container'>
				<h1 className='prize-history__title'>История призов</h1>
				<h3 className='prize-history__title'>
					Здесь вы сможете отслеживать призы, которые получали
				</h3>
				<div className='prize-history__content'>
					<div className='prize-history__wrapper'>
						<ul className='prize-history__list prize-wrapper'>
							<li className='prize-history__list-item prize-history__titles'>
								Дата выигрыша
							</li>
							<li className='prize-history__list-item prize-history__titles'>
								Приз
							</li>
						</ul>
						{getPaginatedData().map((prize, index) => (
							<ul key={index} className='prize-history__list prize-wrapper'>
								<li className='prize-history__list-item orders'>
									{prize.date}
								</li>
								<li className='prize-history__list-item orders'>
									{prize.prize}
								</li>
							</ul>
						))}
					</div>
					{renderPagination()}
				</div>
			</div>
		</section>
	)
}

export default PrizeHistory
