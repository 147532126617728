import React, { useState, useEffect } from 'react'
import axios from 'axios'
import LogoMezex from './../../img/icons/mezex 1.webp'
import './authorizationform.css'

const AuthorizationForm = ({ onClose }) => {
	const [phone, setPhone] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [isChecked, setIsChecked] = useState(false)
	const [step, setStep] = useState(1)
	const [isVisible, setIsVisible] = useState(true)
	const [userData, setUserData] = useState(null)
	const [confirmationCode, setConfirmationCode] = useState('')
	const [receivedCode, setReceivedCode] = useState('')
	const [isForgotPassword, setIsForgotPassword] = useState(false)
	const [newPassword, setNewPassword] = useState('')
	const [isCodeSent, setIsCodeSent] = useState(false)
	const [timer, setTimer] = useState(120)
	const [isTimerActive, setIsTimerActive] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)

	const handlePhoneChange = inputValue => {
		const cleanedValue = inputValue.replace(/\D/g, '')
		if (cleanedValue && cleanedValue[0] !== '7') {
			setPhone(`7${cleanedValue.slice(1)}`)
		} else {
			setPhone(cleanedValue)
		}
	}

	const handleCheckPhone = async () => {
		try {
			const response = await axios.post(
				process.env.REACT_APP_HOST + '/reg/check_user',
				{ phone },
				{
					auth: {
						username: process.env.REACT_APP_LOGIN,
						password: process.env.REACT_APP_PASSWORD,
					},
				}
			)

			if (response && response.data) {
				const exists = response.data.exists
				if (!exists) {
					setStep(3)
				} else {
					const ban_status = response.data.ban_status
					if (ban_status) {
						alert('Данный аккаунт находится в блокировке')
						setPhone('')
						setPassword('')
						setConfirmPassword('')
						setStep(1)
					} else {
						setStep(2)
					}
				}
			}
		} catch (error) {
			console.error('Ошибка проверки телефона:', error)
		}
	}

	const handleLogin = async () => {
		try {
			const response = await axios.post(
				process.env.REACT_APP_HOST + '/reg/check_password',
				{ phone, password },
				{
					auth: {
						username: process.env.REACT_APP_LOGIN,
						password: process.env.REACT_APP_PASSWORD,
					},
				}
			)
			const correctPassword = response.data.correct_pass

			if (correctPassword) {
				const userResponse = await axios.post(
					process.env.REACT_APP_HOST + '/data/user',
					{ phone },
					{
						auth: {
							username: process.env.REACT_APP_LOGIN,
							password: process.env.REACT_APP_PASSWORD,
						},
					}
				)
				const userData = userResponse.data
				setUserData(userData)
				localStorage.setItem('isLoggedIn', true)
				localStorage.setItem('userData', JSON.stringify(userData))
				localStorage.setItem('userPhone', phone)
				window.location.reload()
			} else {
				alert('Неверный пароль! Пожалуйста, попробуйте еще раз.')
			}
		} catch (error) {
			console.error('Ошибка авторизации:', error)
		}
	}

	const handleRegister = async () => {
		setIsSubmitting(true)
		try {
			await axios.post(
				process.env.REACT_APP_HOST + '/reg/add/user',
				{
					phone,
					password,
				},
				{
					auth: {
						username: process.env.REACT_APP_LOGIN,
						password: process.env.REACT_APP_PASSWORD,
					},
				}
			)
			handleLogin()
		} catch (error) {
			console.error('Ошибка регистрации:', error)
		}
	}

	const handleSendCodeClick = async () => {
		try {
			if (!isCodeSent) {
				setIsCodeSent(true)
				const response = await axios.post(
					process.env.REACT_APP_HOST + '/reg/confirm_phone',
					{ phone },
					{
						auth: {
							username: process.env.REACT_APP_LOGIN,
							password: process.env.REACT_APP_PASSWORD,
						},
					}
				)
				const code = response.data.code
				setReceivedCode(code)
				setIsTimerActive(true)
				setTimer(120)
			} else {
				console.log('Код уже отправлен.')
			}
		} catch (error) {
			console.error('Ошибка отправки кода: ', error)
			alert('Не удалось отправить код. Пожалуйста, попробуйте еще раз.')
		}
	}

	useEffect(() => {
		if (step === 3 && !isCodeSent) {
			handleSendCodeClick()
			setIsCodeSent(true)
		}
	}, [step])

	useEffect(() => {
		let intervalId

		if (isTimerActive) {
			intervalId = setInterval(() => {
				setTimer(prevTimer => prevTimer - 1)
			}, 1000)
		}

		if (timer === 0) {
			setIsTimerActive(false)
			setIsCodeSent(false)
		}

		return () => clearInterval(intervalId)
	}, [isTimerActive, timer])

	const handleConfirmCode = async () => {
		try {
			const response = await axios.post(
				process.env.REACT_APP_HOST + '/reg/check_code',
				{ phone, code: confirmationCode },
				{
					auth: {
						username: process.env.REACT_APP_LOGIN,
						password: process.env.REACT_APP_PASSWORD,
					},
				}
			)

			const correctCode = response.data.correct_code

			if (correctCode) {
				setStep(4)
			} else {
				alert('Неверный код подтверждения. Пожалуйста, попробуйте еще раз.')
			}
		} catch (error) {
			console.error('Ошибка подтверждения кода:', error)
			alert('Не удалось подтвердить код. Пожалуйста, попробуйте еще раз.')
		}
	}

	const handleChangePassword = async () => {
		try {
			if (newPassword === confirmPassword) {
				await axios.post(
					process.env.REACT_APP_HOST + '/data/update/password',
					{
						phone,
						new_password: newPassword,
					},
					{
						auth: {
							username: process.env.REACT_APP_LOGIN,
							password: process.env.REACT_APP_PASSWORD,
						},
					}
				)

				alert('Пароль был изменен!')
				setStep(2)
			} else {
				alert(
					'Пароли не совпадают. Пожалуйста, проверьте пароль и подтверждение пароля.'
				)
			}
		} catch (error) {
			console.error('Ошибка смены пароля:', error)
			alert('Не удалось изменить пароль. Пожалуйста, попробуйте еще раз.')
		}
	}

	const handleSubmit = async e => {
		e.preventDefault()

		if (isSubmitting) return

		try {
			if (step === 1) {
				await handleCheckPhone()
			} else if (step === 2) {
				await handleLogin()
			} else if (step === 3) {
				await handleConfirmCode()
			} else if (step === 4) {
				if (isForgotPassword) {
					await handleChangePassword()
				} else {
					if (password === confirmPassword && isChecked) {
						await handleRegister()
					} else {
						if (password !== confirmPassword) {
							alert(
								'Пароли не совпадают. Пожалуйста, проверьте пароль и подтверждение пароля.'
							)
						} else if (!isChecked) {
							alert(
								'Пожалуйста, отметьте согласие на обработку персональных данных.'
							)
						}
					}
				}
			}
		} catch (error) {
			console.error('Error:', error)
		}
	}

	const handleClose = e => {
		e.stopPropagation()
		setIsVisible(false)
		onClose()
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const phone = localStorage.getItem('phone')
				if (phone) {
					const timestamp = Date.now()
					const response = await axios.post(
						process.env.REACT_APP_HOST + `/data/user?timestamp=${timestamp}`,
						{ phone },
						{
							auth: {
								username: process.env.REACT_APP_LOGIN,
								password: process.env.REACT_APP_PASSWORD,
							},
						}
					)
					const userData = response.data
					setUserData(userData)
					localStorage.setItem('userData', JSON.stringify(userData))
				}
			} catch (error) {
				console.error('Error fetching user data:', error)
			}
		}

		fetchData()
	}, [])

	return (
		<form className='authorization-form' onSubmit={handleSubmit}>
			<div className='close-button' onClick={handleClose}>
				<div className='line1'></div>
				<div className='line2'></div>
			</div>
			<div className='authorization__name'>
				<img src={LogoMezex} alt='Mezex Logo' className='authorization-logo' />
				<h3 className='authorization__title'>Вход MezEx</h3>
			</div>
			{step === 1 && (
				<div className='input-first-step'>
					<div className='input-box'>
						<input
							type='tel'
							placeholder='Телефон'
							required
							autoFocus
							minLength={11}
							maxLength={11}
							value={phone}
							onChange={e => handlePhoneChange(e.target.value)}
							className='input-box__size'
						/>
						<p className='input-addition__note'>
							Регистрируйтесь по номеру, который привязан к СБП (на этот номер
							будет приходить ваше вознаграждение)
						</p>
					</div>
					<button className='submit' type='submit' disabled={isSubmitting}>
						Далее
					</button>
				</div>
			)}
			{step === 2 && (
				<>
					<div className='input-box'>
						<input
							type='password'
							placeholder='Пароль'
							required
							minLength={8}
							value={password}
							onChange={e => setPassword(e.target.value)}
							className='input-box__size'
						/>
					</div>
					<div className='entry'>
						<button
							className='submit link'
							type='button'
							onClick={handleLogin}
							disabled={isSubmitting}
							style={{ backgroundColor: isSubmitting ? '#d3d3d3' : '' }}
						>
							Войти
						</button>
						<a
							className='forgot__note'
							onClick={() => {
								setIsForgotPassword(true)
								setStep(3)
							}}
						>
							Забыли пароль?
						</a>
					</div>
				</>
			)}
			{step === 3 && (
				<>
					<div className='input-box'>
						<input
							type='text'
							placeholder='Код из СМС'
							required
							value={confirmationCode}
							onChange={e => setConfirmationCode(e.target.value)}
							className='input-box__size'
						/>
						<button
							className='send-code'
							onClick={handleSendCodeClick}
							disabled={isCodeSent || isTimerActive || isSubmitting}
							style={{ color: isCodeSent ? '#9d9d9d' : '' }}
						>
							{isCodeSent
								? `Отправить код повторно (${timer})`
								: 'Отправить код повторно'}
						</button>
					</div>
					<button
						className='submit'
						type='button'
						onClick={handleConfirmCode}
						disabled={isSubmitting || !confirmationCode.trim()}
					>
						Подтвердить
					</button>
				</>
			)}

			{step === 4 && (
				<>
					<div className='input-confirmation'>
						<p className='input-addition__note'>
							<span>Придумайте пароль для входа</span>
						</p>
						<div className='input-box'>
							<input
								type='password'
								placeholder='Пароль'
								required
								minLength={8}
								value={isForgotPassword ? newPassword : password}
								onChange={e => {
									if (isForgotPassword) {
										setNewPassword(e.target.value)
									} else {
										setPassword(e.target.value)
									}
								}}
								className='input-box__size'
							/>
						</div>
						<div className='input-box'>
							<input
								type='password'
								placeholder='Подтверждение пароля'
								required
								minLength={8}
								value={confirmPassword}
								onChange={e => setConfirmPassword(e.target.value)}
								className='input-box__size'
							/>
						</div>
						{!isForgotPassword && (
							<div className='checkbox-box'>
								<input
									type='checkbox'
									id='isChecked'
									checked={isChecked}
									onChange={e => setIsChecked(e.target.checked)}
									className='input-box__checkbox'
								/>
								<a
									href='https://documents.themezex.com/themezex'
									target='_blank'
									className='input__note'
								>
									Согласие на обработку персональных данных
								</a>
							</div>
						)}
					</div>
					<button
						className='submit'
						type='button'
						onClick={handleSubmit}
						disabled={
							isSubmitting ||
							(isForgotPassword && (!newPassword || !confirmPassword)) ||
							(!isForgotPassword &&
								(!password || !confirmPassword || !isChecked))
						}
						style={{ backgroundColor: isSubmitting ? '#d3d3d3' : '' }}
					>
						{isForgotPassword ? 'Изменить пароль' : 'Авторизоваться'}
					</button>
				</>
			)}
		</form>
	)
}

export default AuthorizationForm
