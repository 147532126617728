import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ApplicationForm from '../components/authorizedBlocks/application/Application'
import LeaderBoard from '../components/authorizedBlocks/leaderboard/LeaderBoard'
import MyApplications from '../components/myapplications/MyApplications'

const Application = () => {
	const navigate = useNavigate()

	useEffect(() => {
		// Проверка авторизации при загрузке компонента
		const loggedIn = localStorage.getItem('isLoggedIn')
		if (!loggedIn) {
			// Если пользователь не авторизован, перенаправляем его на главную страницу
			navigate('/')
		}
	}, [navigate])

	return (
		<>
			<ApplicationForm />
			<LeaderBoard />
			<MyApplications />
		</>
	)
}

export default Application
