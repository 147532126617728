import './contacts.css'

const Contacts = () => {
	return (
		<section className='contacts ' id='contacts'>
			<div className='contacts__background'>
				<div className='contacts__container'>
					<div className='contacts__content'>
						<h1 className='contacts__title'>Контакты</h1>
						<ul className='contacts__list'>
							<li className='contacts__list-item'>
								<p className='contacts__desc'>Отдел по работе с клиентами</p>
								<a href='tel:83422770075' className='contacts__desc'>
									{' '}
									8 (342) 277-00-75
								</a>
							</li>
							<li className='contacts__list-item'>
								<p className='contacts__desc'>По общим вопросам</p>
								<a href='tel:83422592773' className='contacts__desc'>
									{' '}
									8 (342) 259-27-73
								</a>
							</li>
							<li className='contacts__list-item'>
								<a href='mailto:themezex@gmail.com' className='contacts__desc'>
									themezex@gmail.com
								</a>
							</li>
						</ul>
					</div>
					<div className='contacts__map'>
						<iframe
							src='https://yandex.ru/map-widget/v1/?um=constructor%3A166abc0839523652a040cdc8e3252781df7a457e32f549bb563a88fb8d6fa43c&amp;source=constructor'
							frameborder='0'
						></iframe>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Contacts
