import React, { useState, useEffect } from 'react'
import FortuneWheel from '../../wheeloffortune/WheelOfFortune'
import RankSystem from '../../ranksystem/RankSystem'
import './levelandbonuses.css'

import RankInfo from './../../../img/icons/ApplicationInfo.svg'
import ExclamationPoint from './Rank-info-exclamation-point.svg'

const LevelAndBonuses = () => {
	const [isPremiumWheel, setIsPremiumWheel] = useState(false)
	const [showBonuses, setShowBonuses] = useState(false)
	const [showRankInfo, setShowRankInfo] = useState(false)
	const [target, setTarget] = useState(0)
	const [deal, setDeal] = useState(0)

	useEffect(() => {
		const userData = JSON.parse(localStorage.getItem('userData'))
		if (userData) {
			setTarget(userData.target)
			setDeal(userData.deal)
		}
	}, [localStorage.getItem('userData')])

	const togglePremiumWheel = () => {
		setIsPremiumWheel(prevState => !prevState)
	}

	const toggleBonuses = () => {
		setShowBonuses(prevState => !prevState)
	}

	const toggleRankInfo = () => {
		setShowRankInfo(prevState => !prevState)
	}

	useEffect(() => {
		if (showRankInfo) {
			const timeoutId = setTimeout(() => {
				document
					.querySelector('.level-and-bonuses__rank-text')
					.classList.add('show')
			}, 100)
			return () => clearTimeout(timeoutId)
		}
	}, [showRankInfo])

	useEffect(() => {
		if (showBonuses) {
			const timeoutId = setTimeout(() => {
				document.querySelector('.level-and-bonuses__list').classList.add('show')
			}, 100)
			return () => clearTimeout(timeoutId)
		}
	}, [showBonuses])

	useEffect(() => {
		if (showBonuses) {
			const handleClickOutside = event => {
				if (
					!event.target.closest('.level-and-bonuses__list') &&
					!event.target.closest('.level-and-bonuses__rank-bonuses')
				) {
					setShowBonuses(false)
				}
			}
			document.addEventListener('click', handleClickOutside)

			return () => {
				document.removeEventListener('click', handleClickOutside)
			}
		}
	}, [showBonuses])

	return (
		<section className='level-and-bonuses' id='level-and-bonuses'>
			<div className='level-and-bonuses__container'>
				<h1 className='level-and-bonuses__title'>Уровень и бонусы</h1>
				<div className='level-and-bonuses__information'>
					<div className='level-and-bonuses__content'>
						<h3 className='level-and-bonuses__title'>Мой ранг</h3>
						<RankSystem />
						<div className='level-and-bonuses__rank-info'>
							{!showRankInfo && (
								<button className='level-and-bonuses__rank-button'>
									<img
										src={RankInfo}
										alt=''
										onClick={toggleRankInfo}
										className='level-and-bonuses__rank-button-img'
									/>
								</button>
							)}
							{showRankInfo && (
								<div className='level-and-bonuses__rank-text'>
									<div className='exclamation-point'>
										<img
											src={ExclamationPoint}
											alt=''
											className='exclamation-point-img'
										/>
									</div>
									<p className='level-and-bonuses__explanation'>
										Количество целевых рекомендаций влияет на Ваш уровень.{' '}
										<br />
										<span>
											Чем больше рекомендаций — тем выше уровень. <br />{' '}
											Вознаграждение
										</span>{' '}
										зависит от Вашего уровня и конечного результата работы с
										клиентом по Вашей рекомендации. <br />
										Помимо денежных вознаграждений за ваши рекомендации мы дарим
										подарки с помощью <span>колеса удачи!</span>
									</p>
									<div className='close-button-rank' onClick={toggleRankInfo}>
										<div className='line1-rank'></div>
										<div className='line2-rank'></div>
									</div>
								</div>
							)}
						</div>
						<div className='level-and-bonuses__button-info'>
							<button
								className='level-and-bonuses__rank-bonuses'
								onClick={toggleBonuses}
							>
								Бонусы ранга
							</button>
							{showBonuses && (
								<ul className='level-and-bonuses__list'>
									<li className='level-and-bonuses__list-item'>
										<h3 className='level-and-bonuses__title'>
											<span>Целевая заявка: </span> {target}р
										</h3>
									</li>
									<li className='level-and-bonuses__list-item'>
										<h3 className='level-and-bonuses__title'>
											<span>Целевая актуальная заявка: </span>
											{target * 3}р
										</h3>
									</li>
								</ul>
							)}
							<button className='level-and-bonuses__find-more'>
								<a href='#popular-questions'> Узнать подробнее</a>
							</button>
						</div>
					</div>
					<FortuneWheel
						isPremiumWheel={isPremiumWheel}
						setIsPremiumWheel={setIsPremiumWheel}
						togglePremiumWheel={togglePremiumWheel}
					/>
				</div>
			</div>
		</section>
	)
}

export default LevelAndBonuses
