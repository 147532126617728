import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './buttonlevel.css'

import AuthorizationForm from '../authorizationform/AuthorizationForm'

const ButtonLevel = () => {
	const [isAuthorizationOpen, setIsAuthorizationOpen] = useState(false)
	const authorizationFormRef = useRef(null)
	const navigate = useNavigate()

	const isLoggedIn = localStorage.getItem('isLoggedIn')

	const toggleAuthorization = () => {
		if (isLoggedIn) {
			navigate('/level-and-bonuses')
		} else {
			setIsAuthorizationOpen(!isAuthorizationOpen)
		}
	}

	const handleClose = () => {
		setIsAuthorizationOpen(false)
	}

	const handleClickOutside = event => {
		if (
			authorizationFormRef.current &&
			!authorizationFormRef.current.contains(event.target)
		) {
			setIsAuthorizationOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<>
			<button className='button-help__link' onClick={toggleAuthorization}>
				<div>Узнать мой уровень</div>
			</button>
			{isAuthorizationOpen && (
				<div ref={authorizationFormRef}>
					<AuthorizationForm onClose={handleClose} />
				</div>
			)}
		</>
	)
}

export default ButtonLevel
