import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import axios from 'axios'
import AuthorizationForm from '../authorizationform/AuthorizationForm'
import Settings from '../settings/Settings'
import LogoMezex from '../../img/icons/mezex 1.webp'
import LogoAuthorizationBlue from '../../img/icons/Mask group.svg'
import LogoAuthorizationGreen from '../../img/icons/LogoAuthorizationGreen.svg'
import './header.css'

const Header = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [userName, setUserName] = useState('')
	const [balance, setBalance] = useState('')
	const [userData, setUserData] = useState(null)
	const [showSettings, setShowSettings] = useState(false)
	const [isAuthorizationOpen, setIsAuthorizationOpen] = useState(false)
	const settingsRef = useRef()
	const authorizationFormRef = useRef(null)
	const navigate = useNavigate()

	useEffect(() => {
		const loggedIn = localStorage.getItem('isLoggedIn')
		setIsLoggedIn(!!loggedIn)

		if (loggedIn) {
			const fetchData = async () => {
				try {
					const phone = localStorage.getItem('userPhone')
					if (phone) {
						const timestamp = Date.now()
						const response = await axios.post(
							process.env.REACT_APP_HOST + `/data/user?timestamp=${timestamp}`,
							{ phone },
							{
								auth: {
									username: process.env.REACT_APP_LOGIN,
									password: process.env.REACT_APP_PASSWORD,
								},
							}
						)
						const userData = response.data
						localStorage.setItem('userData', JSON.stringify(userData))
						if (userData) {
							setUserData(userData)
							setBalance(userData.balance)
							setUserName(userData.user_name)
						}
						if (userData.ban_status) {
							localStorage.removeItem('isLoggedIn')
							window.location.reload()
							localStorage.removeItem('phone')
							localStorage.removeItem('userData')
						}
					} else {
						console.error('Phone number not found in local storage.')
					}
				} catch (error) {
					console.error('Error fetching user data:', error)
				}
			}

			fetchData()
		}
	}, [])

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}

	const handleLogout = () => {
		localStorage.removeItem('isLoggedIn')
		window.location.href = '/'
		localStorage.removeItem('userEmail')
		localStorage.removeItem('userData')
	}

	const handleSettingsClick = () => {
		setShowSettings(true)
	}

	const handleCloseSettings = () => {
		setShowSettings(false)
	}

	const toggleAuthorization = () => {
		setIsAuthorizationOpen(!isAuthorizationOpen)
	}

	const handleClickOutside = event => {
		if (
			authorizationFormRef.current &&
			!authorizationFormRef.current.contains(event.target)
		) {
			setIsAuthorizationOpen(false)
		}
		if (settingsRef.current && !settingsRef.current.contains(event.target)) {
			setShowSettings(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleNavLinkClick = (event, path) => {
		if (!isLoggedIn) {
			event.preventDefault()
			localStorage.setItem('redirectPath', path)
			toggleAuthorization()
		}
		if (isMenuOpen) {
			setIsMenuOpen(false)
		}
	}

	return (
		<header className='header'>
			<div className='header__container'>
				<nav className='header__nav'>
					<a href='https://themezex.com/' target='_blank'>
						<img src={LogoMezex} alt='/' className='header__logo' />
					</a>
					<div className={`header__actions ${isMenuOpen ? 'open' : ''}`}>
						<ul className={`header__list ${isMenuOpen ? 'open' : ''}`}>
							{isLoggedIn ? (
								<li className='header__list-item header-burger-account burger-version'>
									<div href='/' className='header__desc header__desc-username'>
										{userName}
									</div>
									<div className='header__balance'>
										<p className='header__desc'>
											<span>{balance}р</span>
										</p>
									</div>
								</li>
							) : (
								<div className='header__authorization'>
									<img
										src={LogoAuthorizationGreen}
										alt=''
										className='header__avatar-green'
									/>
									<a
										href='#'
										className='header__desc '
										onClick={toggleAuthorization}
									>
										Авторизация
									</a>
								</div>
							)}

							<li className='header__list-item'>
								<NavLink
									to='/'
									className={({ isActive }) =>
										`header__desc ${isActive ? 'header__desc-active' : ''}`
									}
									onClick={() => setIsMenuOpen(false)}
								>
									Главная
								</NavLink>
							</li>
							<li className='header__list-item'>
								<NavLink
									to='/application'
									className={({ isActive }) =>
										`header__desc ${isActive ? 'header__desc-active' : ''}`
									}
									onClick={event => handleNavLinkClick(event, '/application')}
								>
									Рекомендации
								</NavLink>
							</li>
							<li className='header__list-item'>
								<NavLink
									to='/level-and-bonuses'
									className={({ isActive }) =>
										`header__desc ${isActive ? 'header__desc-active' : ''}`
									}
									onClick={event => handleNavLinkClick(event, '/prizes')}
								>
									Уровень и бонусы
								</NavLink>
							</li>
							<li className='header__list-item'>
								<NavLink
									to='/accruals'
									className={({ isActive }) =>
										`header__desc ${isActive ? 'header__desc-active' : ''}`
									}
									onClick={event => handleNavLinkClick(event, '/accruals')}
								>
									Начисления
								</NavLink>
							</li>
							<li className='header__list-item'>
								<NavLink
									to='/help'
									className={({ isActive }) =>
										`header__desc ${isActive ? 'header__desc-active' : ''}`
									}
									onClick={event => handleNavLinkClick(event, '/help')}
								>
									Помощь
								</NavLink>
							</li>

							{isLoggedIn ? (
								<>
									<li className='header__list-item burger-version'>
										<a
											href='#'
											className='header__desc'
											onClick={event => {
												event.preventDefault()
												handleSettingsClick()
												setIsMenuOpen(false)
											}}
										>
											Профиль
										</a>
									</li>
									<li className='header__list-item  burger-version'>
										<a className='header__desc exit' onClick={handleLogout}>
											Выйти
										</a>
									</li>
								</>
							) : (
								<></>
							)}
						</ul>
					</div>

					<div className='header__account'>
						{isLoggedIn ? (
							<>
								<div href='/' className='header__desc header__desc-username'>
									{userName}
									<div className='header__wrapper'>
										<a
											href='#'
											className='header__desc'
											onClick={event => {
												event.preventDefault()
												handleSettingsClick()
											}}
										>
											Профиль
										</a>
										<a className='header__desc exit' onClick={handleLogout}>
											Выйти
										</a>
									</div>
								</div>
								<div className='header__balance'>
									<p className='header__desc'>
										<span>{balance}р</span>
									</p>
								</div>
							</>
						) : (
							<div className='header__authorization-main'>
								<img
									src={LogoAuthorizationBlue}
									alt=''
									className='header__avatar-blue'
								/>
								<a
									href='#'
									className='header__desc'
									onClick={event => {
										event.preventDefault()
										toggleAuthorization()
									}}
								>
									Авторизация
								</a>
							</div>
						)}
					</div>

					<div className='header__burger' onClick={toggleMenu}>
						<div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
						<div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
						<div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
					</div>
				</nav>
			</div>
			{showSettings && (
				<div ref={settingsRef}>
					<Settings
						setShowSettings={setShowSettings}
						onClose={handleCloseSettings}
					/>
				</div>
			)}
			{isAuthorizationOpen && (
				<div ref={authorizationFormRef}>
					<AuthorizationForm onClose={() => setIsAuthorizationOpen(false)} />
				</div>
			)}
		</header>
	)
}

export default Header
