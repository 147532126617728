import React, { useState } from 'react'
import axios from 'axios'
import ApplicationPopup from '../../applicationpopup/ApplicationPopup'
import './application.css'

import ButtonAdd from './../../../img/icons/plus.svg'
import ButtonRemove from './../../../img/icons/Remove.svg'
import ApplicationInfoPopup from './../../../img/icons/ApplicationInfoPopup.svg'

function ApplicationForm() {
	const [applicants, setApplicants] = useState([
		{ fullName: '', phone: '', comment: '' },
	])
	const [isPopupOpen, setIsPopupOpen] = useState(false)

	const handleInputChange = (index, event) => {
		const { name, value } = event.target
		const list = [...applicants]
		list[index][name] = value
		setApplicants(list)
	}

	const handlePhoneChange = (index, event) => {
		const { name, value } = event.target
		const cleanedValue = value.replace(/[^\d()+\s-]/g, '')
		const list = [...applicants]
		list[index][name] = cleanedValue
		setApplicants(list)
	}

	const handleSubmit = async event => {
		event.preventDefault()

		const phone = localStorage.getItem('userPhone')
		if (!phone) {
			alert('Не удалось получить телефон пользователя')
			return
		}

		const nonEmptyApplicants = applicants.filter(
			applicant =>
				(applicant.fullName && applicant.fullName.trim() !== '') ||
				(applicant.phone && applicant.phone.trim() !== '') ||
				(applicant.comment && applicant.comment.trim() !== '')
		)

		const invalidApplicants = nonEmptyApplicants.filter(
			applicant =>
				((applicant.fullName && applicant.fullName.trim() !== '') ||
					(applicant.comment && applicant.comment.trim() !== '')) &&
				(!applicant.phone || applicant.phone.trim() === '')
		)

		if (invalidApplicants.length > 0) {
			alert('Пожалуйста, заполните поле "Телефон" для всех записей')
			return
		}

		if (nonEmptyApplicants.length === 0) {
			alert('Пожалуйста, заполните хотя бы одно поле перед отправкой')
			return
		}

		try {
			const response = await axios.post(
				process.env.REACT_APP_HOST + '/rec/add/contacts',
				{ contacts: nonEmptyApplicants, phone: phone },
				{
					auth: {
						username: process.env.REACT_APP_LOGIN,
						password: process.env.REACT_APP_PASSWORD,
					},
				}
			)
			alert('Данные успешно отправлены')
			setApplicants([{ fullName: '', phone: '', comment: '' }])
		} catch (error) {
			alert('Ошибка при отправке данных')
		}
	}

	const handleAddApplicant = () => {
		setApplicants([...applicants, { fullName: '', phone: '', comment: '' }])
	}

	const handleRemoveApplicant = index => {
		if (applicants.length > 1) {
			const list = [...applicants]
			list.splice(index, 1)
			setApplicants(list)
		}
	}

	const handlePaste = event => {
		event.preventDefault()
		const pastedData = event.clipboardData.getData('Text')
		if (pastedData.includes('\t')) {
			const parts = pastedData.split(/\r?\n/)
			const newApplicants = parts
				.map(line => {
					if (line.trim() !== '') {
						const columns = line.split('\t')
						if (columns && columns.length >= 2) {
							let fullName = columns[1].trim()
							let phone = columns[0].trim()
							let comment = columns.length >= 3 ? columns[2].trim() : ''

							if (
								!/^(79|\+79|7\(9|\+7\(9|89|\+89|\+8\(9)\d{9}$/.test(phone) &&
								/^[\d\s\-()+]+$/.test(fullName)
							) {
								fullName = columns[0].trim()
								phone = columns[1].trim()
							}

							if (fullName !== '' || phone !== '' || comment !== '') {
								if (
									!phone.startsWith('+79') &&
									!phone.startsWith('79') &&
									!phone.startsWith('7(9') &&
									!phone.startsWith('+7(9') &&
									!phone.startsWith('8(9') &&
									!phone.startsWith('+89') &&
									!phone.startsWith('89') &&
									!phone.startsWith('+8(9')
								) {
									phone = null
								}
								return { fullName, phone, comment }
							} else {
								return null
							}
						}
					}
					return null
				})
				.filter(applicant => applicant !== null)

			const applicantsWithoutEmptyInput = applicants.filter(
				applicant =>
					applicant.fullName.trim() !== '' ||
					applicant.phone.trim() !== '' ||
					applicant.comment.trim() !== ''
			)

			if (newApplicants.length > 0) {
				newApplicants.push({ fullName: '', phone: '', comment: '' })
			}

			setApplicants([...applicantsWithoutEmptyInput, ...newApplicants])
		} else {
			alert('Вставленные данные некорректны')
		}
	}

	const handleClearAll = () => {
		setApplicants([{ fullName: '', phone: '', comment: '' }])
	}

	const openPopup = () => {
		setIsPopupOpen(true)
	}

	const closePopup = () => {
		setIsPopupOpen(false)
	}

	return (
		<section className='application' id='application'>
			<div className='application__container'>
				<h1 className='application__title'>Добавить рекомендации</h1>
				<div className='application__main'>
					<div className='application__content'>
						<form className='application__form' onSubmit={handleSubmit}>
							{applicants.map((applicant, index) => (
								<div className='application__input' key={index}>
									<span className='input-number'>{index + 1}</span>
									<input
										type='text'
										name='fullName'
										className='fullName'
										placeholder='ФИО'
										value={applicant.fullName}
										onChange={e => handleInputChange(index, e)}
									/>
									<div className='application-line'></div>
									<input
										type='tel'
										placeholder='Телефон*'
										required
										name='phone'
										className='phone'
										value={applicant.phone}
										onChange={e => handlePhoneChange(index, e)}
									/>
									<div className='application-line'></div>
									<input
										type='text'
										name='comment'
										className='comment'
										placeholder='Комментарий'
										value={applicant.comment}
										onChange={e => handleInputChange(index, e)}
									/>
									<button
										type='button'
										className='application__remove'
										onClick={() => handleRemoveApplicant(index)}
									>
										<img
											src={ButtonRemove}
											alt=''
											className='application__remove-img'
										/>
									</button>
								</div>
							))}
							<button
								type='button'
								className='application__add'
								onClick={handleAddApplicant}
							>
								<img src={ButtonAdd} alt='' />
							</button>
						</form>
					</div>
					<div className='application__sending'>
						<div className='application__list'>
							<textarea
								onPaste={handlePaste}
								className='application__note'
								placeholder='Добавьте несколько контактов одновременно. Скопируйте несколько телефонов или имен и телефонов из Excel, нажмите на это поле и вставьте целый список. Если ошиблись - нажмите на "Очистить все" или крестик'
							></textarea>
							<button className='application__info-popup' onClick={openPopup}>
								<img
									src={ApplicationInfoPopup}
									alt=''
									className='application__info-popup'
								/>
							</button>
						</div>
						<div className='application__link'>
							{' '}
							<button
								className='application__clear-all'
								onClick={handleClearAll}
							>
								Очистить всё
							</button>
							<button
								type='submit'
								className='application__sending-button'
								onClick={handleSubmit}
							>
								Отправить!
							</button>
						</div>
					</div>
				</div>
			</div>
			{isPopupOpen && <ApplicationPopup onClose={closePopup} />}
		</section>
	)
}

export default ApplicationForm
