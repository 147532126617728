import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Accrual from '../components/accrual/Accrual'

const ProfileAccrualHistory = () => {
	const navigate = useNavigate()

	useEffect(() => {
		// Проверка авторизации при загрузке компонента
		const loggedIn = localStorage.getItem('isLoggedIn')
		if (!loggedIn) {
			// Если пользователь не авторизован, перенаправляем его на главную страницу
			navigate('/')
		}
	}, [navigate])

	return (
		<>
			<Accrual />
		</>
	)
}

export default ProfileAccrualHistory
