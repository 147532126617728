import './footer.css'

const Footer = () => {
	return (
		<section className='footer'>
			<div className='footer-background'>
				<div className='footer__container'>
					<ul className='footer__list'>
						<li className='footer__list-item'>
							<p className='footer__desc'>
								Общество с ограниченной ответственностью "Мезекс. Информационные
								системы" (ООО "Мезекс.Информационные системы").
							</p>
						</li>
						<li className='footer__list-item'>
							<p className='footer__desc'>
								IT-компания, решение о предоставлении государственной
								аккредитации организации, осуществляющей деятельность в области
								информационных технологий от 17.05.2022 No
								АО-20220517-4564781889-3. Товарный знак (знак обслуживания) No
								804635.
							</p>
						</li>
						<li className='footer__list-item'>
							<p className='footer__desc'>
								ОГРН 1225900009411, ИНН 5904401319, юридический адрес: 614010,
								Пермский край, г. Пермь, ул. Куйбышева, д. 95б, офис 1201/6.
							</p>
						</li>
						<li className='footer__list-item'>
							<p className='footer__desc'>
								Информация на сайте не является публичной офертой и носит
								информационно-справочный характер. Все права защищены.
							</p>
						</li>
						<li className='footer__list-item'>
							<a
								href='https://docs.google.com/document/d/1hh7ZmfSMxIKRv7qTnwnxBY94MUJ6S-Wl4MXKwxwTHxw/edit?hl=ru&pli=1'
								target='_blank'
								className='footer__desc personal-information'
							>
								Документация пользователя
							</a>
						</li>
						<li className='footer__list-item'>
							<a
								href='https://drive.google.com/file/d/1wak7MhbJEJc8NhyIebGA7nK-aRWz_lRF/view'
								target='_blank'
								className='footer__desc personal-information'
							>
								Согласие посетителя сайта на обработку персональных данных.
							</a>
						</li>
					</ul>
				</div>
			</div>
		</section>
	)
}

export default Footer
